import { Button, Box, Icon, Text } from "@chakra-ui/react";
import { memo } from "react";
import { BiCustomize, BiCartDownload, BiListUl, BiBookOpen } from "react-icons/bi";

import MnualPDF from '../../resources/HokutoNet_Manual_20240801.pdf'


type Props = {
  onClickStandardItem: () => void;
  onClickOrder: () => void;
  onClickStandardOrder: () => void;
  onClickOrderHistory: () => void;
  onClickOrderDetailHistory: () => void;
};

export const SideBar = memo((props: Props) => {
  const {
    onClickStandardItem,
    onClickOrder,
    onClickStandardOrder,
    onClickOrderHistory,
    onClickOrderDetailHistory,
  } = props;
  return (
    <Box
      bg="gray.50"
      h="100%"
      position="fixed"
      top="100px"
      left={0}
      w="250px"
      display={{ base: "none", md: "block" }}
      zIndex={400}
    >
      {/* 定番品登録ボタン */}
      <Button
        onClick={onClickStandardItem}
        h="60px"
        w="100%"
        borderRadius="0"
        bg="gray.50"
        color="#333333"
        _hover={{ bg: "yellow.100" }}
        justifyContent="flex-start"
        mt="20px">
        <Icon as={BiCustomize} h="25px" w="25px" color="yellow.500" />
        <Text pl="10px" fontWeight="lighter" fontSize="16px">定番品登録</Text>
      </Button>
      {/* 発注入力（商品一覧）ボタン */}
      <Button
        onClick={() => onClickOrder()}
        h="60px"
        w="100%"
        borderRadius="0"
        bg="gray.50"
        color="#333333"
        _hover={{ bg: "blue.100" }}
        justifyContent="flex-start">
        <Icon as={BiCartDownload} h="25px" w="25px" color="blue.500" />
        <Text pl="10px" fontWeight="lighter" fontSize="16px">発注入力（商品一覧）</Text>
      </Button>
      {/* 発注入力（定番品一覧）ボタン */}
      <Button
        onClick={() => onClickStandardOrder()}
        h="60px"
        w="100%"
        borderRadius="0"
        bg="gray.50"
        color="#333333"
        _hover={{ bg: "blue.100" }}
        justifyContent="flex-start">
        <Icon as={BiCartDownload} h="25px" w="25px" color="blue.500" />
        <Text pl="10px" fontWeight="lighter" fontSize="16px">発注入力（定番品一覧）</Text>
      </Button>
      {/* 発注伝票一覧ボタン */}
      <Button
        onClick={onClickOrderHistory}
        h="60px"
        w="100%"
        borderRadius="0"
        bg="gray.50"
        color="#333333"
        _hover={{ bg: "green.100" }}
        justifyContent="flex-start">
        <Icon as={BiListUl} h="25px" w="25px" color="green.500" />
        <Text pl="10px" fontWeight="lighter" fontSize="16px">発注伝票一覧</Text>
      </Button>
      {/* 発注明細一覧ボタン */}
      <Button
        onClick={onClickOrderDetailHistory}
        h="60px"
        w="100%"
        borderRadius="0"
        bg="gray.50"
        color="#333333"
        _hover={{ bg: "green.100" }}
        justifyContent="flex-start">
        <Icon as={BiListUl} h="25px" w="25px" color="green.500" />
        <Text pl="10px" fontWeight="lighter" fontSize="16px">発注明細一覧</Text>
      </Button>
      {/* 操作マニュアルボタン */}
      <Button
        as="a"
        href={MnualPDF}
        download="HokutoNet_Manual_20240801.pdf"
        h="60px"
        w="100%"
        mt="60px"
        borderRadius="0"
        bg="gray.50"
        color="#333333"
        _hover={{ bg: "purple.100" }}
        justifyContent="flex-start">
        <Icon as={BiBookOpen} h="25px" w="25px" color="purple.500" />
        <Text pl="10px" fontWeight="lighter" fontSize="16px">操作マニュアル</Text>
      </Button>
    </Box>
  );
});
import React from 'react'
import { useAuthContext } from '../providers/AuthProvider';
import { Navigate } from 'react-router-dom';

type PrivateRouteProps = {
  element: React.ReactElement;
}

// 認証されたユーザーのみがアクセスできるルート
// ※未認証の場合ログイン画面に遷移
export const PrivateRoute = (props: PrivateRouteProps) => {
  const { element } = props;
  const { isAuth } = useAuthContext();

  if (isAuth) {
    return element;
  }

  return <Navigate to="/" />

}

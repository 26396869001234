import React, { FC } from 'react'

import { IconButton } from '@chakra-ui/react'

import { RiArrowUpDoubleFill } from "react-icons/ri";

type Props = {
  showButton: boolean;
}

export const PageTopScrollButton: FC<Props> = (props) => {
  const { showButton } = props;

  return (
    <IconButton
      aria-label='PageTopScrollButton'
      variant="solid"
      icon={<RiArrowUpDoubleFill />}
      display={showButton ? "block" : "none"} // ボタンをToggle
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}  // 上までSmoothスクロール
      cursor="pointer"
      position="fixed"
      right={{ base: 6, md: 8 }}
      bottom={{ base: 8, md: 10 }}
      bgColor="none"
      color="#006AB7"
      w={16}
      h={16}
      fontSize="50px"
      isRound={true}
      opacity="0.8"
      p={2}
      boxShadow="md"
      _hover={{
        bgColor: "gray.200",
      }}
    />
  )
}

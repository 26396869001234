import { useCallback } from 'react'

import { ApiClient } from '../utility/Utility';
import { RequestCartDelete } from '../types/CartDataType';

// カート情報を削除するカスタムフック
export const useDeleteCarts = () => {

  const deleteCartData = useCallback(async (request: RequestCartDelete) => {
    // console.log("deleteCartData");

    await ApiClient.delete('api/cart/destroy/', { data: request })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
      });

  }, []);

  return { deleteCartData };
};

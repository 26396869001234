/* eslint-disable react-hooks/exhaustive-deps */

import React, { ChangeEvent, FC, Fragment, KeyboardEvent, memo, useCallback, useEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom';

import { useErrorBoundary } from 'react-error-boundary';

import {
  Input,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  NumberInput,
  NumberInputField,
  Stack,
  Heading,
  Flex,
  Box,
  Text,
  Select,
  Spacer,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";

import { BsSearch, BsCartPlus } from "react-icons/bs";

// コンポーネント
import { ApiClient, MaxRange, MessageBoxKbn, MinRange, ShoriKbn, TeibanFlag } from '../../utility/Utility';
import { MessageBox } from '../atoms/MessageBox';
import { RequestAddCart } from '../../types/CartDataType';
import { ShohinListData } from '../../types/ShohinListDataType';
import { PrimaryButton } from '../atoms/buttons/PrimaryButton';
import { PageTopScrollButton } from '../atoms/buttons/PageTopScrollButton';

// カスタムHook
import { useProducts } from "../../hooks/useProducts"
import { useHandleArrowKey } from '../../hooks/useHandleArrowKey';
import { useAddCarts } from '../../hooks/useAddCarts';
import { useDeleteCarts } from '../../hooks/useDeleteCarts';
import { usePageTopScroll } from '../../hooks/usePageTopScroll';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useAuthContext } from '../../providers/AuthProvider';
import { useGetTanka } from '../../hooks/useGetTanka';
import { useGetCarts } from '../../hooks/useGetCarts';
import { useGetIndexData } from '../../hooks/useGetIndexData';
import { useGetInfo } from '../../hooks/useGetInfo';
import { ModalInfo } from '../atoms/ModalInfo';

// 大分類　型設定
type DaiBunrui = {
  mydaibuncd: string;
  myshobunmei: string;
}

// 中分類　型設定
type ChuBunrui = {
  mychubuncd: string;
  myshobunmei: string;
}

type Props = {
  teibanFlag: number;
}

// 発注入力画面
export const Order: FC<Props> = memo((props) => {

  const { showBoundary } = useErrorBoundary();
  const { getIndexData } = useGetIndexData();

  const { teibanFlag } = props;

  // メッセージ表示
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [messageBoxKbn, setMessageBoxKbn] = useState(0);
  // メッセージボックスに表示するメッセージ
  const [alertMessage, setAlertMessage] = useState("");

  // 商品一覧取得のためのリクエストパラメータ 初期値
  const [request, setRequest] = useState({
    login_id: '',  // ログインデータ
    teban_reg: 1,   // ログインデータ
    teban_flg: 0,   // 画面遷移、商品一覧：0　定番商品一覧：1
    mydaibuncd: "",  // 大分類選択時
    mychubuncd: 0, // 中分類選択時
    sho_name: ""
  })

  // 数量変更した商品をセット
  const [selectShohin, setSelectShohin] = useState<Array<ShohinListData>>([]);

  const [daiBunrui, setDaiBunrui] = useState<Array<DaiBunrui>>([]);
  const [chuBunrui, setChuBunrui] = useState<Array<ChuBunrui>>([]);
  const [shohinMei, setShohinmei] = useState("");

  // 商品一覧データを取得API
  const { getShohinListData, shohinList, shohinResponseStatus } = useProducts();

  // カート更新フラグ
  const [addCartFlg, setAddCartFlg] = useState(false);
  // カート情報更新API
  const { addCart } = useAddCarts();
  // カート情報削除API
  const { deleteCartData } = useDeleteCarts();
  // カート情報取得API
  const { getCartData, carts } = useGetCarts()
  // 商品単価取得API
  const { getTanka, shohinTanka, setCartTanka } = useGetTanka()

  // 矢印キー押下時処理設定
  const { onkeydownNum } = useHandleArrowKey();

  const navigate = useNavigate();
  const location = useLocation();

  // ページトップへ移動する処理
  const { watchScroll, showButton } = usePageTopScroll();

  // ログインデータ取得
  const { login } = useAuthContext();

  // ローカルストレージから取得した値
  const { storedData, setStoredData } = useLocalStorage({
    storedDenNo: 0,
    storedNouDate: "",
    storedBiko: "",
  })

  // お知らせ情報取得
  const { getInfoUnreadList, infoUnreadList } = useGetInfo();
  // お知らせ画面開閉用
  const {
    isOpen: isInfoOgen,
    onOpen: onInfoOpen,
    onClose: onInfoClose,
  } = useDisclosure();


  // スクロールイベントを拾う処理
  useEffect(() => {
    window.addEventListener("scroll", watchScroll);
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);


  // API -------------------------------------------------------------------------------
  // 大分類カテゴリーデータ取得処理
  const getDaiBunrui = useCallback(async () => {
    await ApiClient
      .get<Array<DaiBunrui>>('api/shobun/dai/')
      .then((response) => {
        // console.log(response);
        setDaiBunrui(response.data);
      })
      .catch((error) => {
        // console.log("error");
        // console.log(error);
      })
  }, [])

  // 中分類カテゴリーデータ取得処理（大分類選択時に実施）
  const getChuBunrui = useCallback(async (mydaibuncd: string) => {
    // console.log("getChubun");

    // 中分類の値を初期化
    // ※中分類を選択している状態で大分類が変更されたときに初期化されないため
    setChuBunrui([]);

    if (mydaibuncd === "") {
      return;
    } else {
      await ApiClient
        .get(`api/shobun/chu/${mydaibuncd}`)
        .then((response) => {
          // console.log(response);
          setChuBunrui(response.data);
        })
        .catch((error) => {
          // console.log("error");
          // console.log(error);
        })
    }
  }, [])
  // API -------------------------------------------------------------------------------

  // 最新状態保持用
  useEffect(() => {
    getIndexData();
  }, [])

  useEffect(() => {
    // ログイン時、未読のお知らせがある場合のみ表示する
    if (infoUnreadList) {
      onInfoOpen()
    }
  }, [infoUnreadList])

  // 定番フラグを変更した場合再レンダリングする
  useEffect(() => {
    (async () => {
      // console.log(localStorage)
      try {
        if (login) {

          const state = localStorage.getItem('state');

          if (state && JSON.parse(state) === ShoriKbn.Refer) {
            // 注文照会画面から遷移してきた場合、カートを削除する

            await deleteCartData({
              login_id: login.login_id,
              sho_cd: "",
              packno: 0,
            })

            localStorage.removeItem('state')
            localStorage.removeItem('storedData');

            // ローカルストレージ内のデータを初期化
            setStoredData({
              storedDenNo: 0,
              storedNouDate: "",
              storedBiko: "",
            })

            // console.log(localStorage)
          }

          // 商品一覧取得リクエストパラメータに値をセット
          setRequest({
            ...request,
            login_id: login.login_id,   // ログインデータ
            teban_reg: login.login_data.teban_reg,           // ログインデータ
            teban_flg: (teibanFlag === null ? 0 : teibanFlag),
            sho_name: "",
          });

          // 初期表示の場合、初期値をセット
          setDaiBunrui([]);
          setChuBunrui([]);
          setShohinmei("");


          // ログイン時に未読のお知らせ情報を取得。お知らせがある場合、お知らせ画面を表示する
          if (teibanFlag === TeibanFlag.ShohinIchiran
            && location.state
            && location.state.login === true) {
            await getInfoUnreadList({ login_id: login.login_id })
          }

          // 大分類APIをコール
          await getDaiBunrui();

          // console.log(request);
          // 商品一覧取得
          await getShohinListData({
            login_id: login.login_id,   // ログインデータ
            teban_reg: login.login_data.teban_reg,           // ログインデータ
            teban_flg: (teibanFlag === null ? 0 : teibanFlag),
            mydaibuncd: "",
            mychubuncd: 0,
            sho_name: "",
          });

          // ページトップへ移動(スクロール)
          window.scrollTo({ top: 0, behavior: "smooth" });

        }
      } catch (e) {
        showBoundary(e)
      }

    })();

  }, [teibanFlag])


  // 大分類選択時処理
  const onChangeDaiBunrui = (e: ChangeEvent<HTMLSelectElement>) => {
    // console.log("onChangeDaiBunrui")
    // console.log(e.target.value);
    try {
      // 中分類取得
      getChuBunrui(e.target.value);

      // リクエストパラメータを更新
      // 大分類が変更されたときの中分類コードを初期化
      setRequest({
        ...request,
        mydaibuncd: e.target.value,
        mychubuncd: 0,
      });
    } catch (e) {
      showBoundary(e);
    }
  };

  //　中分類選択時処理
  const onChangeChuBunrui = (e: ChangeEvent<HTMLSelectElement>) => {
    // console.log("onChangeChuBunrui")
    // console.log(e.target.value);
    try {
      // リクエストパラメータを更新
      setRequest({
        ...request,
        mychubuncd: Number(e.target.value),
      });
    } catch (e) {
      showBoundary(e);
    }
  };

  // 商品名変更処理
  const onChangeShohinMei = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log("onChangeShohinMei")
    try {
      setShohinmei(e.target.value);

      setRequest({
        ...request,
        sho_name: e.target.value,
      });
    } catch (e) {
      showBoundary(e);
    }
  };

  // 検索ボタン押下時処理
  const onClickSearch = async () => {
    // console.log("onClickSearch");
    try {
      // 商品一覧を再表示する
      await getShohinListData(request);
    } catch (e) {
      showBoundary(e);
    }
  };

  // エラーメッセージ
  const errorMessage = (e: any) => {
    switch (e.message) {
      case 'suryo':
        setAlertMessage(`入力可能な数量を入力してください。\n入力可能な数量は${MinRange + 1}以上、${MaxRange}以下になります。`);
        break;
      case 'noneSuryo':
        setAlertMessage("数量を入力してください。");
        break;
      case 'decimalPlaces':
        setAlertMessage("小数点以下は入力できません。");
        break;
      default:
        showBoundary(e);
        break;
    }
  }

  const suryoValidation = (validData: any) => {
    const validCart = validData.filter((data: any) => data.suryo < MinRange || data.suryo > MaxRange)
    const decimalPlaces = validData.filter((data: any) => !Number.isInteger(data.suryo))
    // 数量が0未満又は999999999以上の時エラーメッセージを表示
    if (validCart.length > 0) {
      throw RangeError('suryo')
    } else if (decimalPlaces.length > 0) {
      throw RangeError('decimalPlaces')
    } else {
      return;
    }
  }

  // 数量変更処理
  const onChangeNum = (e: ChangeEvent<HTMLInputElement>, key_no: string) => {
    try {
      const suryo: number = '' ? 0 : Number(e.target.value)

      const selectShohinIndex = selectShohin.findIndex((shohin) => shohin.key_no === key_no);

      if (selectShohinIndex !== -1) {
        // 既に選択している商品の数量を更新
        const updateSelectShohin = [...selectShohin];
        updateSelectShohin[selectShohinIndex] = { ...updateSelectShohin[selectShohinIndex], suryo: suryo };
        setSelectShohin(updateSelectShohin);
      } else {
        // 新しい商品を追加
        const newShohin = shohinList.find((shohinData) => shohinData.key_no === key_no);
        const newSelectShohin = newShohin ? [...selectShohin, { ...newShohin, suryo: suryo }] : selectShohin;
        setSelectShohin(newSelectShohin);
      };
    } catch (e) {
      showBoundary(e);
    }
  };

  // 単価再取得時にカートを更新する
  useEffect(() => {
    (async () => {
      try {
        if (shohinTanka) {
          // カート内の単価を更新する
          // console.log(shohinTanka);
          await setCartTanka(shohinTanka, carts);

          navigate("/OrderConfirmation", { state: { shoriKbn: storedData.storedDenNo === 0 ? ShoriKbn.Add : ShoriKbn.Update } });

        }
      } catch (e) {
        showBoundary(e);
      }
    })()
  }, [shohinTanka])

  // カート更新処理（addCartFlg:Trueの場合処理を実行）
  useEffect(() => {
    (async () => {
      try {
        if (addCartFlg) {
          if (!login) return;

          const changeShohin = selectShohin.filter((shohin) => shohin.suryo >= 0);

          // カートに入っている商品データを、数量が1以上の商品リスト
          // ※既に変更された商品と重複しないようにし、商品リストのキーと名称を統一する（単位のキー名が違ったためunitmeiで統一）
          const cartData = carts.filter((data) =>
            data.suryo > 0 && !changeShohin.find((shohin) => shohin.sho_cd === data.sho_cd && shohin.packno === data.packno)
          ).map((data) => ({
            sho_cd: data.sho_cd,
            sho_name: data.sho_name,
            naiyoryo: data.naiyoryo,
            naiyounit: data.naiyounit,
            packno: data.packno,
            packirisu: data.packirisu,
            packunit: data.packunit,
            unitmei: data.packunitmei,
            tanka: data.tanka,
            suryo: data.suryo,
            zaiko_kbn: data.zaiko_kbn,
          }));

          // console.log(changeShohin);

          // console.log(apiSelectShohin);

          // 数量変更した商品とカート内の商品を結合
          const addShohin = [...changeShohin, ...cartData].filter((shohin) => shohin.suryo > 0);

          // カート内容の作成(カート情報更新時に渡すリクエストパラメータ)
          const newCartData = addShohin.map((shohin) => ({
            login_id: login.login_id,           // ログイン時に取得しているデータから持ってくる
            den_no: storedData.storedDenNo,     // 伝票番号ある場合はセットできるようにする(※修正時に発注入力画面に遷移した場合は伝票番号あり) 
            biko: storedData.storedBiko,        // 修正時は保持
            sho_cd: shohin.sho_cd,
            sho_name: shohin.sho_name,
            naiyoryo: shohin.naiyoryo,
            naiyounit: shohin.naiyounit,
            packno: shohin.packno,
            packirisu: shohin.packirisu,
            packunit: shohin.packunit,
            packunitmei: shohin.unitmei,
            tanka: shohin.tanka,
            suryo: shohin.suryo,
            zaiko_kbn: shohin.zaiko_kbn,
          }));

          const cartRequest: RequestAddCart = {
            cart_data: newCartData
          }
          // console.log(newCartData);

          if (newCartData.length === 0) {
            // 選択した商品がない場合カート内を削除

            // カート情報削除API
            deleteCartData({
              login_id: login.login_id,
              sho_cd: "",
              packno: 0,
            });

            // setAddCartFlg(false)

            // 数量が0又は未入力の場合、エラーメッセージ表示
            throw RangeError('noneSuryo')
          } else {

            // 伝票番号が0の場合新規、それ以外は修正
            const shoriKbn = newCartData[0].den_no === 0 ? ShoriKbn.Add : ShoriKbn.Update;

            // カート情報更新APIをコール
            await addCart(cartRequest);

            if (!storedData
              || storedData.storedNouDate === ""
              || login.login_data.nou_kano_date === storedData.storedNouDate) {

              // ページ遷移
              navigate("/OrderConfirmation", { state: { shoriKbn: shoriKbn } });
            } else {

              // 納品日が変更されている場合に商品単価を取得する。
              // 商品単価取得後にカート内の単価を更新する。（※この処理は単価のStateが更新後に行うのでuseEffectで発生させる）
              await getCartData({ login_id: login.login_id });

              getTanka({ login_id: login.login_id, nou_date: storedData.storedNouDate })
            }
          }
        }
      } catch (e) {
        if (e instanceof RangeError) {
          errorMessage(e)

          setMessageBoxKbn(MessageBoxKbn.Warning);
          onOpen();
        } else {
          showBoundary(e)
        }
      } finally {
        // 通常処理、エラーにかかわらずsetAddCartフラグをリセットする
        setAddCartFlg(false)
      }
    })()
  }, [addCartFlg])

  // カートに入れるボタン押下時処理
  const onClickAddCart = async () => {
    // console.log("onClickAddCart");
    // console.log(storedData);
    // console.log(selectShohin)

    try {
      if (!login) return;

      // 入力チェック
      suryoValidation(selectShohin);

      // 現在のカート情報を取得
      await getCartData({ login_id: login.login_id });

      // カート情報取得後にカートを更新する
      setAddCartFlg(true);

    } catch (e) {
      if (e instanceof RangeError) {
        errorMessage(e);

        setMessageBoxKbn(MessageBoxKbn.Warning);
        onOpen();
      } else if (e instanceof Error) {
        showBoundary(e);
      } else {
        showBoundary(e);
      }
    }
  }

  // 数量入力欄でEnterキーを押した場合、カートに入れるボタン押下時と同様の処理を行う
  const onkeydownNumEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    try {
      // Enterキー以外のキーの場合はスルー
      if (e.key !== "Enter") return;

      // Enterキーのデフォルトの操作を無効にする
      e.preventDefault();
      // 
      onClickAddCart();

    } catch (e) {
      showBoundary(e);
    }
  };

  return (
    <>
      <Stack m={{ base: 2, md: 4 }}>
        <Heading as="h1" size="md" textAlign="left">
          発注入力
        </Heading>

        <Box display={{ xl: "flex" }} mt="10px">
          <Box display={{ sm: "Flex" }}>

            {/* 大分類コンボボックス */}
            <Box w={{ base: "auto", sm: "250px" }} maxW="250px" mr={{ base: "0px", sm: "35px" }}>
              <Text size="xs">大分類</Text>
              <Select onChange={onChangeDaiBunrui} defaultValue="">
                <option value="">選択してください</option>
                {daiBunrui.map((category) => (
                  <option key={category.mydaibuncd} value={category.mydaibuncd}>{category.myshobunmei}</option>
                ))}
              </Select>
            </Box>

            {/* 中分類コンボボックス */}
            <Box w={{ base: "auto", sm: "250px" }} maxW="250px" mr={{ base: "0px", xl: "35px" }} mt={{ base: "10px", sm: "0px" }}>
              <Text size="xs">中分類</Text>
              <Select onChange={onChangeChuBunrui} defaultValue="">
                <option value="">選択してください</option>
                {chuBunrui.map((category) => (
                  <option key={category.mychubuncd} value={category.mychubuncd}>{category.myshobunmei}</option>
                ))}
              </Select>
            </Box>
          </Box>

          <Box display={{ sm: "flex" }} mt={{ base: "10px", xl: "0px" }}>
            <Box
              w={{ base: "auto", sm: "250px" }}
              maxW="250px"
              // mr={{ base: "0px", sm: "185px", xl: "35px" }}>
              mr={{ base: "0px", sm: "35px" }}>
              <Text size="xs">商品名</Text>
              <Input onChange={onChangeShohinMei} value={shohinMei} />
            </Box>
            {/* <Flex mt="25px" justifyContent="flex-end"> */}
            <Flex mt="25px" justifyContent="flex-end" w={{ base: "auto", sm: "250px", xl: "100px" }}>
              <PrimaryButton
                onClick={onClickSearch}
                bgGradient="linear(blue.400, blue.600)"
                color="white"
                width={100}
                icon={<Icon as={BsSearch} mt="3px" boxSize="14px" />}
              >検索</PrimaryButton>
            </Flex>
          </Box>
        </Box>

        <Flex mt="10px">
          {/* ページネーション */}

          <Spacer />
          {/* カートに入れるボタン */}
          <PrimaryButton
            onClick={onClickAddCart}
            bgGradient="linear(blue.400, blue.600)"
            color="white"
            width={200}
            margin={1}
            icon={<Icon as={BsCartPlus} color="white" boxSize="16px" />}
          >カートに入れる</PrimaryButton>
        </Flex>

        {/* 商品一覧テーブル */}
        <TableContainer overflowX={{ base: "scroll", md: "auto" }} w="100%" whiteSpace="normal" >
          <Table size="sm">
            <Thead bg="#006DA7">
              <Tr>
                <Th w={{ base: "auto", md: "60%" }} colSpan={3}>
                  商品名
                </Th>
                <Th maxW="90px" minW="68px" rowSpan={2}>
                  数量
                </Th>
                <Th minW="80px" rowSpan={2}>
                  単位
                </Th>
                <Th minW="118px" rowSpan={2}>
                  商品コード
                </Th>
              </Tr>
              <Tr>
                <Th minW="85px" w={{ base: "auto", md: "20%" }}>内容量</Th>
                <Th minW="80px" w={{ base: "auto", md: "20%" }}>入数</Th>
                <Th minW="80px" w={{ base: "auto", md: "20%" }}>単価</Th>
              </Tr>
            </Thead>
            <Tbody>
              {shohinList?.map((data, index) => (
                <Fragment key={data.key_no}>
                  {/* 交互に行の背景色を変更 */}
                  <Tr bg={index % 2 === 0 ? "white" : "#D4E5EF"}>
                    <Td w={{ base: "auto", md: "60%" }} colSpan={3} textAlign="left">{data.sho_name}</Td>
                    <Td maxW="90px" p={0} rowSpan={2}>

                      <NumberInput
                        clampValueOnBlur={false}
                        defaultValue={data.suryo === 0 ? "" : data.suryo}
                        m={0}
                        max={MaxRange}
                        min={MinRange}
                        size="sm"
                      >
                        <NumberInputField
                          onChange={(e) => {
                            onChangeNum(e, data.key_no);
                          }}
                          onKeyDown={(e) => {
                            onkeydownNum(e);
                            onkeydownNumEnter(e);
                          }}
                          fontSize="18px"
                          borderColor="gray.500"
                          h={{ base: "85px", md: "auto" }} minH="68px" pr={3} textAlign="right" />
                      </NumberInput>
                    </Td>
                    <Td rowSpan={2}>
                      {data.unitmei}
                    </Td>
                    <Td rowSpan={2}>
                      {data.sho_cd}
                    </Td>
                  </Tr>
                  <Tr bg={index % 2 === 0 ? "white" : "#D4E5EF"}>
                    <Td w={{ base: "auto", md: "20%" }} textAlign="right">
                      {data.naiyoryo === 0 ? "" : `${Number(data.naiyoryo.toFixed(3)).toLocaleString(undefined, { minimumFractionDigits: 3 })}${data.naiyounit}`}
                    </Td>
                    <Td w={{ base: "auto", md: "20%" }} textAlign="right">{data.packirisu}</Td>
                    <Td w={{ base: "auto", md: "20%" }} textAlign="right">
                      {
                        data.tanka % 1 === 0
                          ? data.tanka.toLocaleString()
                          : data.tanka.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                      }円
                    </Td>
                  </Tr>
                </Fragment>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Text display={shohinResponseStatus === 204 ? "brock" : "none"}>対象データがありません。</Text>
      </Stack>

      <MessageBox
        isOpen={isOpen}
        onClose={onClose}
        cancelRef={cancelRef}
        messageBoxKbn={messageBoxKbn}
        alertMessage={alertMessage}
      />
      <PageTopScrollButton showButton={showButton} />

      <ModalInfo isOpen={isInfoOgen} onClose={onInfoClose} unreadKbn={true} data={infoUnreadList?.info_list_data} />
    </>
  );
});



import {
  Center,
  Text
} from "@chakra-ui/react";
import { memo } from "react";

export const Footer = memo(() => {
  return (
    <Center
      bg="blue.500"
      color="white"
      h={{ base: "30px", md: "35px" }}
      position="absolute"
      bottom={0}
      w="full"
      zIndex={500}
    >
      <Text fontSize={{ base: "12px", md: "15px" }}>Copyright &copy; ホクト商事 All Rights Reserved</Text>
    </Center>
  );
});
import React, { FC, Fragment, memo } from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon
} from '@chakra-ui/react';

import { BsExclamationTriangleFill, BsInfoCircleFill, BsQuestionCircleFill, BsXCircleFill } from "react-icons/bs";

import { MessageBoxKbn } from '../../utility/Utility';

export type MessageBoxProps = {
  isOpen: boolean;
  onClose: () => void;
  onClickConfirmation?: () => void;
  onClickInfomation?: () => void;
  onClickWarning?: () => void;
  returnFocusOnClose?: boolean;
  cancelRef: React.RefObject<HTMLButtonElement>;
  messageBoxKbn: number;
  alertMessage: string;
}

export const MessageBox: FC<MessageBoxProps> = memo((props) => {

  const {
    isOpen,
    onClose,
    cancelRef,
    onClickConfirmation = () => { },
    onClickInfomation = () => { },
    onClickWarning = () => { },
    returnFocusOnClose = true,
    messageBoxKbn,
    alertMessage
  } = props;

  let alertHeader: string = "";

  // 確認や注意をメッセージとして出したい場合に分岐させて使用
  switch (messageBoxKbn) {
    case MessageBoxKbn.Infomation:
      alertHeader = "情報";
      break;
    case MessageBoxKbn.Confirmation:
      alertHeader = "確認";
      break;
    case MessageBoxKbn.Warning:
      alertHeader = "警告";
      break;
    case MessageBoxKbn.Error:
      alertHeader = "エラー";
      break;
  }

  // 「\n」の位置で文章を改行する処理
  // ※メッセージに改行がある場合、propsで渡すとうまく改行されないため
  const alertMessageText = alertMessage.split('\n').map((text, index) => {
    return (
      <Fragment key={index}>
        {text}
        <br />
      </Fragment>
    )
  })

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered={true}
      closeOnOverlayClick={false}
      returnFocusOnClose={returnFocusOnClose}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          <Icon
            as={messageBoxKbn === MessageBoxKbn.Infomation ? BsInfoCircleFill
              : messageBoxKbn === MessageBoxKbn.Confirmation ? BsQuestionCircleFill
                : messageBoxKbn === MessageBoxKbn.Warning ? BsExclamationTriangleFill
                  : BsXCircleFill
            }
            boxSize={8}
            color={messageBoxKbn === MessageBoxKbn.Infomation ? "blue.500"
              : messageBoxKbn === MessageBoxKbn.Confirmation ? "blue.500"
                : messageBoxKbn === MessageBoxKbn.Warning ? "yellow.400"
                  : "red.500"}
            mr="10px"
            verticalAlign="bottom"
          />{alertHeader}
        </AlertDialogHeader>
        <AlertDialogBody>
          {alertMessageText}
        </AlertDialogBody>
        <AlertDialogFooter>
          {/* 情報メッセージの時のみ表示するボタン */}
          <Button
            onClick={() => { onClose(); onClickInfomation(); }}
            display={messageBoxKbn === MessageBoxKbn.Infomation ? "brock" : "none"}
            ml={3}
            w="80px"
          >OK</Button>
          {/* 確認メッセージの時のみ表示するボタン */}
          <Button
            onClick={() => { onClose(); onClickConfirmation(); }}
            display={messageBoxKbn === MessageBoxKbn.Confirmation ? "brock" : "none"}
            w="80px"
          >はい</Button>
          {/* 確認、エラーメッセージの時に表示するボタン */}
          <Button
            onClick={() => onClose()}
            ref={cancelRef}
            // 情報メッセージ用のボタンが表示される場合非表示
            display={messageBoxKbn === MessageBoxKbn.Error || messageBoxKbn === MessageBoxKbn.Confirmation ? "brock" : "none"}
            ml={3}
            w="80px"
          >{messageBoxKbn === MessageBoxKbn.Confirmation ? "いいえ" : "OK"}</Button>
          {/* 警告メッセージの時に表示するボタン */}
          <Button
            onClick={() => { onClose(); onClickWarning(); }}
            ref={cancelRef}
            // 情報メッセージ用のボタンが表示される場合非表示
            ml={3}
            display={messageBoxKbn === MessageBoxKbn.Warning ? "brock" : "none"}
            w="80px"
          >OK</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
});

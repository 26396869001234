import { useCallback } from 'react'

import { ApiClient } from '../utility/Utility';

//発注情報削除のリクエスト
type DeleteOrderRequest = {
  login_id: string;
  den_no: number;
}

export const useDeleteOrder = () => {
  const orderDelete = useCallback(async (request: DeleteOrderRequest) => {
    // console.log(request);

    await ApiClient.delete('api/hchu/destroy/', { data: request })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
      });

  }, []);

  return { orderDelete }
}

import { useCallback, useState } from "react"
import { useErrorBoundary } from "react-error-boundary";

import { ApiClient } from "../utility/Utility"
import { GetCartData, RequestAddCart } from "../types/CartDataType";
import { useAddCarts } from "./useAddCarts";

// 商品単価
type ShohinTankaData = {
  sho_cd: string;
  packno: number;
  tanka: number;
}

type ShohinTanka = {
  sho_tanka_data: ShohinTankaData[]
}

// 単価を再取得し、カート情報を更新するカスタムフック
export const useGetTanka = () => {
  const [shohinTanka, setShohinTanka] = useState<ShohinTanka | null>(null);
  const { addCart } = useAddCarts();

  const { showBoundary } = useErrorBoundary();

  // 商品単価情報取得API
  const getTanka = useCallback((request: { login_id: string, nou_date: string }) => {
    ApiClient.post<ShohinTanka>('api/tanka/list/', request)
      .then((response) => {
        // console.log(response)
        setShohinTanka(response.data)
      })
      .catch((error) => {
      })
  }, [])

  // カート内の単価を更新する処理
  const setCartTanka = useCallback(async (shohinTanka: ShohinTanka, carts: Array<GetCartData>) => {
    try {
      const newCartData = carts.map((item) => ({
        login_id: item.login_id,
        den_no: item.den_no,
        biko: item.biko,
        sho_cd: item.sho_cd,
        sho_name: item.sho_name,
        naiyoryo: item.naiyoryo,
        naiyounit: item.naiyounit,
        packno: item.packno,
        packirisu: item.packirisu,
        packunit: item.packunit,
        packunitmei: item.packunitmei,
        tanka: shohinTanka.sho_tanka_data.filter((data) => data.sho_cd === item.sho_cd && data.packno === item.packno)[0].tanka,
        suryo: item.suryo,
        zaiko_kbn: item.zaiko_kbn
      }))

      const requestAddCart: RequestAddCart = {
        cart_data: newCartData
      }

      // カート更新
      await addCart(requestAddCart);
    } catch (e) {
      showBoundary(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { getTanka, shohinTanka, setCartTanka }
}

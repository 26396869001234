import { Button, Flex, Heading, Icon, Stack, Text } from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { memo } from "react";
import { useAuthContext } from "../../providers/AuthProvider";
import logo from "../../resources/logo.png";

type Props = {
  onOpen: () => void;
  onClickLogOut: () => void;
};

export const Header = memo((props: Props) => {
  const { onOpen, onClickLogOut } = props;
  const { login } = useAuthContext();

  return (
    <Flex
      position="fixed"
      bgGradient="linear(#6F94CD, #006AB7, blue.700)"
      color="white"
      top={0}
      w="full"
      h="100px"
      shadow="sm"
      py={4}
      px={3}
      zIndex={500}
    >
      <Flex width="full" align="center" fontSize="sm">
        {/* メニューアイコンボタン */}
        <Button
          w="70px"
          h="70px"
          bg="none"
          color="white"
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          _hover={{ cursor: "pointer" }}
        >
          <Icon as={GiHamburgerMenu} w="60px" h="60px" />
        </Button>
        {/* ヘッダーのタイトル、ユーザ名 */}

        <Flex width="full" justify="space-between">
          <Heading
            as="h1"
            fontSize={{ base: "20px", md: "25px" }}
            pl={3}
            pb={2}
          >
            <img src={logo} alt="" />
            <Text fontSize={{ base: "md", md: "20px" }}>
              {login?.login_data.tok_mei}{" "}
              {login?.login_data.admin_kbn === 1 ? "　" : " 様"}
            </Text>
          </Heading>
          {/* ログアウトボタン */}
          {/* ★IconにonClickのハンドリングを付与できないのでIconButtonかButtonに変更する必要がある? */}
          <Stack
            w={{ base: "60px", md: "80px" }}
            h={{ base: "50px", md: "60px" }}
            alignItems="center"
            cursor="pointer"
            pt={3}
          >
            <Icon
              onClick={onClickLogOut}
              as={RiLogoutBoxRLine}
              w={{ base: "40px", md: "45px" }}
              h={{ base: "40px", md: "45px" }}
            ></Icon>
            <Text fontSize={{ base: "10px", md: "15px" }}>ログアウト</Text>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
});

import React, { FC, memo } from 'react'

import { Input, } from "@chakra-ui/react";

type Props = {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    min?: string;
    defaultValue?: string;
    value?: string;
    width?: number;
    isReadOnly?: boolean;
    onBlur?: React.FocusEventHandler<Element>;
}

export const DateInput: FC<Props> = memo((props) => {
    const {
        onChange,
        min = "1000-01-01",
        defaultValue = undefined,
        value = undefined,
        width = 180,
        isReadOnly,
        onBlur = () => { },
    } = props;

    return (
        <Input
            onChange={onChange}
            max="9999-12-31"
            min={min}
            type="date"
            defaultValue={defaultValue}
            value={value}
            w={{ base: "155px", sm: `${width}px` }}
            px={{ base: "12px", sm: "16px" }}
            isReadOnly={isReadOnly}
            onBlur={onBlur}
        />
    );
})
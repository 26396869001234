import { useCallback, useState } from 'react'

import { ApiClient } from '../utility/Utility';

export type RequestGetTokusaki = {
  login_id: string;
}

export type TokListData = {
  tok_cd: number;
  tok_mei: string;
  login_id: string;
}

type TokuisakiList = {
  tok_list_data: TokListData[]
}

export const useGetTokuisakiList = () => {
  const [tokuisakiData, setTokuisakiData] = useState<Array<TokListData>>([])

  // 得意先取得API
  const getTokuisaki = useCallback(async (request: RequestGetTokusaki) => {

    await ApiClient.post<TokuisakiList>('api/accounts/toklist/', request)
      .then((response) => {
        // console.log(response);
        setTokuisakiData(response.data.tok_list_data);
      })
      .catch((error) => {
        // console.log(error);
      })
  }, [])
  return { getTokuisaki, tokuisakiData }
}

import React, { FC } from 'react'
import {
  Button,
  Card,
  CardBody,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text

} from '@chakra-ui/react';

import { InfoListData } from '../../types/InfoType';
import { useGetInfo } from '../../hooks/useGetInfo';
import { useAddInfoSts } from '../../hooks/useAddInfoSts';
import { useAuthContext } from '../../providers/AuthProvider';
import { formattedDate } from '../../utility/Utility';


export type Props = {
  isOpen: boolean;
  onClose: () => void;
  unreadKbn?: boolean;
  data: InfoListData[] | undefined | null;
}

export const ModalInfo: FC<Props> = (props) => {
  const {
    isOpen,
    onClose,
    unreadKbn = false,
    data
  } = props

  const { login } = useAuthContext();
  const { setInfoUnreadList } = useGetInfo();
  const { addInfoSts } = useAddInfoSts();

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior="outside"
      autoFocus={false}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>お知らせ</ModalHeader>

        {data?.map((item) => (
          <ModalBody key={item.msg_id}>
            <Heading fontSize="18px" color="red.400" pb={2}>{item.msg_title}</Heading>
            <Stack>
              <Text size="xs" color="gray.500">{formattedDate(item.kbgnymd)}</Text>
              <Card bg="red.100" shadow="none">
                <CardBody>
                  <Text>{item.msg_naiyo}</Text>
                </CardBody>
              </Card>
            </Stack>
          </ModalBody>
        ))}

        <ModalFooter>
          <Button onClick={async () => {
            if (unreadKbn && login && data) {
              await addInfoSts(data);
            }
            onClose();
            setInfoUnreadList(null)
          }}>閉じる</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

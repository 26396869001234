import React, { ReactNode, useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js';

import { useDisclosure } from '@chakra-ui/react';

import { ApiClient, MessageBoxKbn } from './Utility';
import { MessageBox } from '../components/atoms/MessageBox';

type Props = {
  children: ReactNode;
}

// APIでエラーが返却された場合の共通処理
export const AxiosErrorHandling = (props: Props) => {
  const { children } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [alertMessage, setAlertMessage] = useState("");
  const [messageBoxKbn, setMessageBoxKbn] = useState(0);

  useEffect(() => {

    const responseIntercepter = ApiClient.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        // console.log(error)

        if (!error.responce) {
          setAlertMessage("エラーが発生しました。");
          setMessageBoxKbn(MessageBoxKbn.Error)
          onOpen();
          Bugsnag.notify(error);

        }

        if (error.response.status < 500) {
          const message: Array<string> = Object.values(error.response.data)
          const errorMessages = message[0]

          const isArray = Array.isArray(errorMessages)

          if (isArray) {
            const errorMessage = errorMessages[0]
            setAlertMessage(errorMessage);

          } else {
            setAlertMessage(errorMessages);
          }

          setMessageBoxKbn(MessageBoxKbn.Warning)
          onOpen()

          Bugsnag.notify(error);

        } else {
          // サーバー関係のエラーはエラーメッセージがうまく取れないためメッセージを固定
          setAlertMessage("エラーが発生しました。");
          setMessageBoxKbn(MessageBoxKbn.Error)
          onOpen();

          Bugsnag.notify(error);

        }

        return Promise.reject(error);

      }
    );

    return () => {
      ApiClient.interceptors.response.eject(responseIntercepter);
    }

  }, [onOpen])

  return (
    <>
      {children}
      <MessageBox
        isOpen={isOpen}
        onClose={onClose}
        cancelRef={React.useRef<HTMLButtonElement>(null)}
        messageBoxKbn={messageBoxKbn}
        alertMessage={alertMessage}
      />
    </>
  )
}
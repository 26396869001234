import { useCallback } from 'react'

// 共通関数
import { ApiClient } from '../utility/Utility';
import { RequestAddCart } from '../types/CartDataType';

// カート情報を更新するカスタムフック
export const useAddCarts = () => {

  // カート情報更新API
  const addCart = useCallback(async (request: RequestAddCart) => {
    // console.log("addCart");

    await ApiClient.post('api/cart/update/', request)
      .then((response) => {
        // console.log(response.status);
      })
      .catch((error) => {
        // console.log(error);
      });

  }, []);

  return { addCart };
};

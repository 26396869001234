import React, { ErrorInfo, memo, ReactNode } from 'react'
import { ErrorBoundary, FallbackProps, useErrorBoundary } from 'react-error-boundary'

import { Button, Heading, Stack, Text } from '@chakra-ui/react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

if (process.env.REACT_APP_NODE_ENV === "development") {
} else {
  // Bugsnagスタート
  Bugsnag.start({
    apiKey: `${process.env.REACT_APP_BUGSNAG_APIKEY}`,
    plugins: [new BugsnagPluginReact()],
  })

}

const BugsnagReact = Bugsnag.getPlugin("react")?.createErrorBoundary(React)

type Props = {
  children: ReactNode;
}

export const ReactErrorBoundary = (props: Props) => {
  const { children } = props;
  return (
    BugsnagReact ?
      <BugsnagReact>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={LogError}>{children}</ErrorBoundary>
      </BugsnagReact>
      :
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={LogError}>{children}</ErrorBoundary>
  )
}

// エラー発生時に差し替えるページ
export const ErrorFallback = memo(({ error }: FallbackProps) => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Stack m={4} w={{ base: "auto", md: "500px" }}>
      <Heading as="h2" size="lg">
        エラーが発生しました
      </Heading>
      <Text>再読み込みまたはリセットボタンを押してください。<br />解消されない場合は管理者にお問い合わせください。</Text>
      <Button onClick={resetBoundary} w="120px">リセット</Button>
    </Stack>
  )
})

export const LogError = (error: Error, info: ErrorInfo) => {
  // コンポーネントやイベントハンドリングエラーのログ漏れを防止
  Bugsnag.notify(error);

  // console.log(error);
  // console.log(info);
}

/* eslint-disable react-hooks/exhaustive-deps */

import React, { ChangeEvent, Fragment, memo, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import {
  Checkbox,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  useDisclosure
} from "@chakra-ui/react";

import { MessageBox } from "../atoms/MessageBox";
import { SecondaryButton } from "../atoms/buttons/SecondaryButton";
import { PageTopScrollButton } from '../atoms/buttons/PageTopScrollButton';
import { MessageBoxKbn } from "../../utility/Utility";
import { RequestUpdateStandardTokItem, RequestUpdateStandardRankItem } from '../../types/StandardItemDataType';
import { useStandardItem } from "../../hooks/useStandardItem"
import { useDeleteStandardItem } from "../../hooks/useDeleteStandardItem"
import { useUpdateStandardItem } from "../../hooks/useUpdateStandardItem"
import { useAuthContext } from "../../providers/AuthProvider";
import { usePageTopScroll } from '../../hooks/usePageTopScroll';
import { useGetIndexData } from "../../hooks/useGetIndexData";

export const StandardItem = memo(() => {
  const { showBoundary } = useErrorBoundary();
  const { getIndexData } = useGetIndexData();

  //チェックされたキーナンバーを取得
  const [selectedKeyNo, setselectedKeyNo] = useState<string[]>([]);

  //チェックされた登録済キーナンバーを取得
  const [unSelectedStandardKeyNo, setUnSelectedStandardKeyNo] = useState<string[]>([]);

  const { getStandardTokItem, getStandardRankItem, standardItem, standardRankItem } = useStandardItem();

  const { deleteStandardTokItem, deleteStandardRankItem } = useDeleteStandardItem();

  // ページトップへ移動する処理
  const { watchScroll, showButton } = usePageTopScroll();

  // ログインデータ
  const { login } = useAuthContext();

  const { updateStandardTokItem, updateStandardRankItem } = useUpdateStandardItem();

  // メッセージ表示
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [messageBoxKbn, setMessageBoxKbn] = useState(0);
  // メッセージの「はい」ボタン押下時の処理区分
  // 削除ボタン（delete):カート内情報の削除、注文確定ボタン（order）:発注情報登録
  // const [messageButtonOnClickKbn, setMessageButtonOnClickKbn] = useState("");
  // メッセージボックスに表示するメッセージ
  const [alertMessage, setAlertMessage] = useState("");

  // 更新後のメッセージを閉じたとき、スクロールフラグを変える
  useEffect(() => {
    if (isOpen === false) {
      if (MessageBoxKbn.Infomation) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [isOpen])

  useEffect(() => {
    // 定番品一覧(得意先単位)取得
    (async () => {

      // 最新状態保持用
      getIndexData();

      try {
        if (login?.login_data.teban_reg === 1) {
          await getStandardTokItem({ login_id: login.login_id });
        } else if (login?.login_data.teban_reg === 2) {
          await getStandardRankItem({ login_id: login.login_id });
        }
      } catch (e) {
        showBoundary(e);
      }
    })();
  }, [])

  // スクロールイベントを拾う処理
  useEffect(() => {
    window.addEventListener("scroll", watchScroll);
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);

  // チェックボックス変更処理
  // ★後で処理の組み方を変更したい（商品がチェックされているかどうかを一つのStateで管理したい)
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, keyNo: string, checked: number) => {
    try {
      const targetData = e.target.checked
      // 定番品マスタに登録されている場合
      if (checked === 1) {
        if (targetData) {
          // チェックされたキーナンバーを含む要素を削除
          setUnSelectedStandardKeyNo(unSelectedStandardKeyNo.filter((data) => data !== keyNo))
        } else {
          // チェックが外れた時にキーナンバーを追加　※定番品画面を初期表示したとき、定番品マスタに登録済み(初めからチェックがついている)の商品がStateにセットされないため
          setUnSelectedStandardKeyNo([
            ...unSelectedStandardKeyNo,
            keyNo,
          ])
        };
      }
      // 定番品マスタに登録されていない場合
      else {
        if (!targetData) {
          // チェックが外れたキーナンバーを含む要素を削除
          setselectedKeyNo(selectedKeyNo.filter((data) => data !== keyNo))
        } else {
          // チェックされたキーナンバーを追加
          setselectedKeyNo([
            ...selectedKeyNo,
            keyNo,
          ])
        };
      }
    } catch (e) {
      showBoundary(e);
    }
  }

  // 更新ボタン押下時の処理
  const onClickUpdate = async () => {
    try {
      // 定番品登録単位が得意先単位のとき
      if (login?.login_data.teban_reg === 1) {

        if (!standardItem) return;

        // 定番品マスタに登録されてなく、チェックされた商品を取得
        const checkeditem = standardItem.sho_list_data.filter((item) => selectedKeyNo.includes(item.key_no));

        // 定番品マスタに登録されており、かつチェックがついている定番品を取得
        const apiStandardItem = standardItem.sho_list_data.filter((item) => item.checked === 1 && !unSelectedStandardKeyNo.includes(item.key_no))

        // チェックされた商品がない（定番品マスタに登録されてなくチェックされた商品が一つもない　かつ　定番品マスタに登録済の商品のチェックがすべて外れている）場合は削除のAPIを呼ぶ
        if (selectedKeyNo.length === 0 && unSelectedStandardKeyNo.length === standardItem.sho_list_data.filter((item) => item.checked === 1).length) {
          await deleteStandardTokItem({ login_id: login.login_id })
        } else {

          // 定番品マスタに登録されている定番品と、新たにチェックした商品を結合
          const updateStandardItem = [...checkeditem, ...apiStandardItem]

          // 定番品(得意先単位)更新時に渡すリクエストパラメータ
          if (updateStandardItem) {
            const newStandardTokItem = updateStandardItem.map((item) => ({
              tokcd: item.tokcd,
              sho_cd: item.sho_cd,
              packno: item.packno
            }));

            const newStandardTokItemData: RequestUpdateStandardTokItem = {
              teban_data: newStandardTokItem
            }

            await updateStandardTokItem(newStandardTokItemData)

          };
        }

        // 完了メッセージ表示
        setMessageBoxKbn(MessageBoxKbn.Infomation);
        setAlertMessage("更新が完了しました。");
        onOpen()
      }
      // 定番品登録単位がランク単位のとき
      else if (login?.login_data.teban_reg === 2) {
        if (!standardRankItem) return;

        // 定番品マスタに登録されてなく、チェックされた商品を取得
        const checkeditem = standardRankItem.sho_list_data.filter((item) => selectedKeyNo.includes(item.key_no));

        // 定番品マスタに登録されており、かつチェックがついている定番品を取得
        const apiStandardItem = standardRankItem.sho_list_data.filter((item) => item.checked === 1 && !unSelectedStandardKeyNo.includes(item.key_no))

        // チェックされた商品がない（定番品マスタに登録されてなくチェックされた商品が一つもない　かつ　定番品マスタに登録済の商品のチェックがすべて外れている）場合は削除のAPIを呼ぶ
        if (selectedKeyNo.length === 0 && unSelectedStandardKeyNo.length === standardRankItem.sho_list_data.filter((item) => item.checked === 1).length) {
          await deleteStandardRankItem({ login_id: login.login_id })
        } else {

          // 定番品マスタに登録されている定番品と、新たにチェックした商品を結合
          const updateStandardItem = [...checkeditem, ...apiStandardItem]

          // 定番品(ランク単位)更新時に渡すリクエストパラメータ
          if (updateStandardItem) {
            const newStandardRankItem = updateStandardItem.map((item) => ({
              rankcd: item.rankcd,
              sho_cd: item.sho_cd,
              packno: item.packno
            }));

            const newStandardRankItemData: RequestUpdateStandardRankItem = {
              teban_rank_data: newStandardRankItem
            }

            await updateStandardRankItem(newStandardRankItemData)

          };
        }

        // 完了メッセージ表示
        setMessageBoxKbn(MessageBoxKbn.Infomation);
        setAlertMessage("更新が完了しました。");
        onOpen()
      };

    } catch (e) {
      showBoundary(e);
    }
  }

  return (
    <>
      <Stack m={{ base: 2, md: 4 }}>
        <Heading as="h1" size="md" textAlign="left">定番品登録</Heading>
        <TableContainer overflowX={{ base: "scroll", md: "auto" }} whiteSpace="normal" mt="5px">
          <Table size="sm">
            <Thead bg="#006DA7">
              <Tr>
                <Th>定番品</Th>
                <Th>商品コード</Th>
                <Th>商品名</Th>
                <Th>単位</Th>
              </Tr>
            </Thead>
            <Tbody display={login ? login.login_data.teban_reg === 1 ? "" : "none" : "none"}>
              {
                standardItem?.sho_list_data.map((data, index) => (
                  <Fragment key={data.key_no}>
                    <Tr bg={index % 2 === 0 ? "white" : "#D4E5EF"}>
                      <Td textAlign="center">
                        <Checkbox
                          bg="white"
                          onChange={(e) => handleCheckboxChange(e, data.key_no, data.checked)}
                          defaultChecked={data.checked === 1 || selectedKeyNo.includes(data.key_no)}
                        />
                      </Td>
                      <Td>{data.sho_cd}</Td>
                      <Td textAlign="left">{data.sho_name}</Td>
                      <Td textAlign="center">{data.unitmei}</Td>
                    </Tr>
                  </Fragment>
                ))
              }
            </Tbody>
            <Tbody
              display={login ? login.login_data.teban_reg === 2 ? "" : "none" : "none"}>
              {
                standardRankItem?.sho_list_data.map((data, index) => (
                  <Fragment key={data.key_no}>
                    <Tr bg={index % 2 === 0 ? "white" : "#D4E5EF"}>
                      <Td textAlign="center">
                        <Checkbox
                          bg="white"
                          onChange={(e) => handleCheckboxChange(e, data.key_no, data.checked)}
                          defaultChecked={data.checked === 1 || selectedKeyNo.includes(data.key_no)}
                        />
                      </Td>
                      <Td>{data.sho_cd}</Td>
                      <Td textAlign="left">{data.sho_name}</Td>
                      <Td textAlign="center">{data.unitmei}</Td>
                    </Tr>
                  </Fragment>
                ))
              }
            </Tbody>
          </Table>
        </TableContainer>
        <SecondaryButton
          onClick={onClickUpdate} bgGradient="linear(blue.400, blue.600)" color="white" width={200} margin={1}>更新</SecondaryButton>
        <Flex justifyContent="end">
          <PageTopScrollButton showButton={showButton} />
        </Flex>
      </Stack>

      <MessageBox
        isOpen={isOpen}
        onClose={onClose}
        cancelRef={cancelRef}
        messageBoxKbn={messageBoxKbn}
        alertMessage={alertMessage}
        returnFocusOnClose={false}
      />

    </>
  );
});
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Icon,
  Text,
} from "@chakra-ui/react";
import { memo } from "react";
import { IoMdClose } from "react-icons/io";
import { BiCustomize, BiCartDownload, BiListUl, BiBookOpen } from "react-icons/bi";

import MnualPDF from '../../resources/HokutoNet_Manual_20240801.pdf'

type Props = {
  onClose: () => void;
  isOpen: boolean;
  onClickStandardItem: () => void;
  onClickOrder: () => void;
  onClickStandardOrder: () => void;
  onClickOrderHistory: () => void;
  onClickOrderDetailHistory: () => void;
};

export const MenuDrawer = memo((props: Props) => {
  const {
    onClose,
    isOpen,
    onClickStandardItem,
    onClickOrder,
    onClickStandardOrder,
    onClickOrderHistory,
    onClickOrderDetailHistory,
  } = props;
  return (
    <Drawer placement="left" size="xs" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerBody p={0} bg="gray.100">
            <Button
              onClick={onClose}
              justifyContent="end"
              _hover={{ bg: "tomato", color: "white" }}
              borderRadius="0"
            >
              <Icon as={IoMdClose} />
            </Button>
            <Button
              color="#333333"
              fontWeight="lighter"
              onClick={() => {
                onClickStandardItem();
                onClose();
              }}
              w="100%"
              h="50px"
              borderRadius="0"
              _hover={{ bg: "yellow.100" }}
              justifyContent="flex-start"
              mt="10px"
            >
              <Icon
                as={BiCustomize}
                h="25px"
                w="25px"
                color="yellow.500"
                ml="30px"
              />
              <Text pl="10px" fontWeight="lighter" fontSize="16px">
                定番品登録
              </Text>
            </Button>
            <Button
              color="#333333"
              fontWeight="lighter"
              onClick={() => {
                onClickOrder();
                onClose();
              }}
              w="100%"
              h="50px"
              borderRadius="0"
              _hover={{ bg: "blue.100" }}
              justifyContent="flex-start"
            >
              <Icon
                as={BiCartDownload}
                h="25px"
                w="25px"
                color="blue.500"
                ml="30px"
              />
              <Text pl="10px" fontWeight="lighter" fontSize="16px">
                発注入力（商品一覧）
              </Text>
            </Button>
            <Button
              color="#333333"
              fontWeight="lighter"
              onClick={() => {
                onClickStandardOrder();
                onClose();
              }}
              w="100%"
              h="50px"
              borderRadius="0"
              _hover={{ bg: "blue.100" }}
              justifyContent="flex-start"
            >
              <Icon
                as={BiCartDownload}
                h="25px"
                w="25px"
                color="blue.500"
                ml="30px"
              />
              <Text pl="10px" fontWeight="lighter" fontSize="16px">
                発注入力（定番品一覧）
              </Text>
            </Button>
            <Button
              color="#333333"
              fontWeight="lighter"
              onClick={() => {
                onClickOrderHistory();
                onClose();
              }}
              w="100%"
              h="50px"
              borderRadius="0"
              _hover={{ bg: "green.100" }}
              justifyContent="flex-start"
            >
              <Icon
                as={BiListUl}
                h="25px"
                w="25px"
                color="green.500"
                ml="30px"
              />
              <Text pl="10px" fontWeight="lighter" fontSize="16px">
                発注伝票一覧
              </Text>
            </Button>
            <Button
              color="#333333"
              fontWeight="lighter"
              onClick={() => {
                onClickOrderDetailHistory();
                onClose();
              }}
              w="100%"
              h="50px"
              borderRadius="0"
              _hover={{ bg: "green.100" }}
              justifyContent="flex-start"
            >
              <Icon
                as={BiListUl}
                h="25px"
                w="25px"
                color="green.500"
                ml="30px"
              />
              <Text pl="10px" fontWeight="lighter" fontSize="16px">
                発注明細一覧
              </Text>
            </Button>
            <Button
              as="a"
              href={MnualPDF}
              download="HokutoNet_Manual_20240801.pdf"
              color="#333333"
              fontWeight="lighter"
              w="100%"
              h="50px"
              mt="50px"
              borderRadius="0"
              _hover={{ bg: "purple.100" }}
              justifyContent="flex-start"
            >
              <Icon
                as={BiBookOpen}
                h="25px"
                w="25px"
                color="purple.500"
                ml="30px"
              />
              <Text pl="10px" fontWeight="lighter" fontSize="16px">
                操作マニュアル
              </Text>
            </Button>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
});

import { useCallback, useState } from 'react'

import { GetCartData, RequestGetCart } from '../types/CartDataType';
import { ApiClient } from '../utility/Utility';


// カート情報を取得するカスタムフック
export const useGetCarts = () => {
  const [carts, setCarts] = useState<Array<GetCartData>>([]);

  // カート情報取得API
  const getCartData = useCallback(async (request: RequestGetCart) => {

    // console.log(request);

    await ApiClient.post<Array<GetCartData>>('api/cart/list/', request)
      .then((response) => {
        setCarts(response.data);
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
      });

  }, []);


  return { carts, setCarts, getCartData }
}

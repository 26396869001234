import { Button, ResponsiveValue } from '@chakra-ui/react';
import React, { FC, memo, ReactNode } from 'react'


type Props = {
  children: ReactNode;
  onClick: () => void;
  bg?: string;
  bgGradient?: string;
  color: string;
  display?: ResponsiveValue<string>;
  fontSize?: number;
  isDisabled?: boolean;
  margin?: number;
  tabIndex?: number;
  height?: number;
  width: number;
}

// アイコンなしボタン
export const SecondaryButton: FC<Props> = memo((props) => {
  const {
    children,
    onClick,
    bg = "",
    bgGradient = "",
    color,
    display = "",
    fontSize = 16,
    height = 35,
    isDisabled = false,
    margin = 0,
    tabIndex = 0,
    width,
  } = props;

  return (
    <Button
      onClick={onClick}
      bg={bg}
      bgGradient={bgGradient}
      color={color}
      display={display}
      fontSize={`${fontSize}px`}
      fontWeight="none"
      h={`${height}px`}
      _hover={{ opacity: 0.8 }}
      isDisabled={isDisabled}
      m={margin}
      tabIndex={tabIndex}
      w={`${width}px`}
    >
      {children}
    </Button>
  );
})

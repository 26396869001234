/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'

import { InfoListData, RequestAddInfoSts } from '../types/InfoType'
import { ApiClient } from '../utility/Utility';
import { useAuthContext } from '../providers/AuthProvider';
import { useErrorBoundary } from 'react-error-boundary';

// お知らせ表示状況を登録するカスタムフック
export const useAddInfoSts = () => {
  const { login } = useAuthContext();
  const { showBoundary } = useErrorBoundary();

  const addInfoSts = useCallback(async (data: InfoListData[]) => {
    try {
      if (!login) return;

      // 表示しているお知らせからリクエストで渡すデータを作成する
      const requestAddInfo = data.map((item) => ({
        login_id: login.login_id,
        msg_id: item.msg_id
      }))

      const request: RequestAddInfoSts = {
        info_sts_data: requestAddInfo
      }

      // お知らせ表示状況登録API
      await ApiClient.post('api/infosts/create/', request)
        .then((response) => {
          // console.log(response.status);
        })
        .catch((error) => {
          // console.log(error);
        });

    } catch (e) {
      showBoundary(e);
    }
  }, [])

  return { addInfoSts }
}

import { useCallback } from 'react'

import { ApiClient } from '../utility/Utility';

type Request = {
    login_id: string;
}

// 定番品を削除するカスタムフック
export const useDeleteStandardItem = () => {

    // 定番品(得意先単位)を削除
    const deleteStandardTokItem = useCallback(async (request: Request) => {

        await ApiClient.delete('api/teban/destroy/', { data: request })
            .then((response) => {
                //console.log(response);
            })
            .catch((error) => {
                //console.log(error);
                throw error
            });

    }, []);

    // 定番品(ランク単位)を削除
    const deleteStandardRankItem = useCallback(async (request: Request) => {

        await ApiClient.delete('api/tebanrank/destroy/', { data: request })
            .then((response) => {
                //console.log(response);
            })
            .catch((error) => {
                //console.log(error);
                throw error
            });

    }, []);

    return { deleteStandardTokItem, deleteStandardRankItem };
};
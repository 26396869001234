
import {
  ChakraProvider,
} from "@chakra-ui/react";

import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./providers/AuthProvider";
import { Router } from "./router/Router";
import { ReactErrorBoundary } from "./utility/ReactErrorBoundary";
import theme from "./theme/theme";

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <ReactErrorBoundary>
        <AuthProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AuthProvider>
      </ReactErrorBoundary>
    </ChakraProvider >
  );
}
import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'
import { StoredLogin } from '../types/LoginDataType';

// AuthContextの型を定義
interface AuthContextProps {
  isAuth: boolean;
  signin: () => void;
  signout: () => void;
  login: StoredLogin | null;
  setLogin: Dispatch<SetStateAction<StoredLogin | null>>;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

// 認証状態を管理するためのコンテキスト
export const useAuthContext = () => {
  return useContext(AuthContext);
};

// 認証状態を管理するためのプロバイダ
export const AuthProvider = (props: { children: ReactNode }) => {
  const { children } = props;

  const item = localStorage.getItem("login");

  // リロードした場合でも認証済みであればページが表示されるようにするために初期値から設定する
  const [isAuth, setIsAuth] = useState(() => {

    return item ? true : false;
  });

  // ローカルストレージのログインデータ
  // ※リロードした場合でも認証済みであればページが表示されるようにするために初期値から設定する
  const [login, setLogin] = useState<StoredLogin | null>(() => {
    //  認証済みでデータ取得出来ていたら値をセット
    return isAuth && item ? JSON.parse(item) : null;
  })

  // ユーザーがログインした時に呼び出される関数
  const signin = () => {
    const item = localStorage.getItem("login");

    if (item) {
      setLogin(JSON.parse(item))
      setIsAuth(true);
    }
  };

  // ユーザーがログアウトした時に呼び出される関数
  const signout = () => {
    // サインアウト時にローカルストレージの値を削除
    localStorage.removeItem('login');
    localStorage.removeItem('storedData')
    localStorage.removeItem('state')

    setLogin(null)
    // 認証状態を不可
    setIsAuth(false);
  };

  return (
    <AuthContext.Provider value={{ isAuth, signin, signout, login, setLogin }}>
      {children}
    </AuthContext.Provider>
  )
}

import JSZip from "jszip";
import { useErrorBoundary } from "react-error-boundary"


export type FileData = {
  filename: string;
  data: string;
}

// CSVファイルをダウンロードするカスタムフック
export const useDownloadCSV = () => {
  const { showBoundary } = useErrorBoundary();

  // CSVファイルを出力
  const downloadCSV = (data: string, filename: string) => {
    try {
      // Excelで表示する際の文字化けを防ぐためBOMを付与
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const blob = new Blob([bom, data], { type: "text/csv" })

      const link = document.createElement("a");
      link.download = filename
      link.href = URL.createObjectURL(blob)
      link.click();

      URL.revokeObjectURL(link.href)
    } catch (e) {
      showBoundary(e);
    }
  }

  // 複数CSVファイルを格納したフォルダを圧縮して出力する
  const downloadZip = async (fileData: Array<FileData>, foldername: string) => {
    try {
      const zip = new JSZip();

      // Excelで表示する際の文字化けを防ぐためBOMを付与
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])

      const data = fileData.map((data) => ({
        path: data.filename,
        blob: new Blob([bom, data.data], { type: "text/csv" })
      }))

      data.map((data) => {
        return zip.file(data.path, data.blob)
      })

      zip.generateAsync({ type: "blob" })
        .then((blob) => {
          const link = document.createElement("a");

          link.href = URL.createObjectURL(blob)
          link.download = foldername
          link.click()

          URL.revokeObjectURL(link.href)
        })

    } catch (e) {
      showBoundary(e);
    }
  }
  return { downloadCSV, downloadZip }
}

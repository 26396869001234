import { useCallback, useState } from 'react'

import { OrderHistoryData } from '../types/OrderHistoryDataType';
import { ApiClient } from "../utility/Utility";

export type OrderHistoryRequest = {
  login_id: string;
  hchu_date_from: string;
  hchu_date_to: string;
  nou_date_from: string;
  nou_date_to: string;
  tok_cd_from: number;
  tok_cd_to: number;
  tan_tok_flg: number;
}

// APIをコールし、発注履歴情報を取得する
export const useOrderHistory = () => {

  const [orderHistory, setOrderHistory] = useState<OrderHistoryData | null>(null);

  const getOrderHistoryData = useCallback(async (request: OrderHistoryRequest) => {

    await ApiClient.post<OrderHistoryData>(`api/hchulog/`, request)

      .then((response) => {
        //console.log(response);
        setOrderHistory(response.data);
      })
      .catch(() => {
        // データ取得できなかった場合の処理=>★エラーメッセージ等 確認してから実装
        //console.log("error")
        setOrderHistory(null);
      })

  }, [])

  return { getOrderHistoryData, orderHistory }
}
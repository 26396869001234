/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, FocusEvent, Fragment, memo, useCallback, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { useErrorBoundary } from "react-error-boundary";

import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Input,
  Select,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import { BsDownload, BsSearch } from "react-icons/bs";

import { ApiClient, MessageBoxKbn, ShoriKbn } from "../../utility/Utility";
import { PrimaryButton } from "../atoms/buttons/PrimaryButton";
import { SecondaryButton } from "../atoms/buttons/SecondaryButton";
import { PageTopScrollButton } from "../atoms/buttons/PageTopScrollButton";
import { MessageBox } from "../atoms/MessageBox";
import { DateInput } from "../atoms/Input/DateInput";
import { RequestAddCart } from "../../types/CartDataType";

import { usePageTopScroll } from "../../hooks/usePageTopScroll";
import { useAddCarts } from "../../hooks/useAddCarts";
import { useGetTokuisakiList } from "../../hooks/useGetTokuisakiList";
import { useDeleteOrder } from "../../hooks/useDeleteOrder";
import { useAuthContext } from "../../providers/AuthProvider";
import { useGetTanka } from "../../hooks/useGetTanka";
import { useGetCarts } from "../../hooks/useGetCarts";
import { useGetOrderList } from "../../hooks/useGetOrderList";
import { FileData, useDownloadCSV } from "../../hooks/useDownloadCSV";
import { useGetIndexData } from "../../hooks/useGetIndexData";
import { ModalInfo } from "../atoms/ModalInfo";
import { useGetInfo } from "../../hooks/useGetInfo";


type RequestGetOrderDetailHistory = {
  login_id: string;
  hchu_date_from: string;
  hchu_date_to: string;
  nou_date_from: string;
  nou_date_to: string;
  sho_name: string;
  tok_cd_from: number;
  tok_cd_to: number;
  tan_tok_flg: number;
}

type Hchu_detail_data = {
  key_no: string;
  login_id: string;
  hchu_date: string;
  sfx: number;
  den_no: number;
  nou_date: string;
  biko: string;
  rnki_ymd: null;
  tokcd: number;
  tokmei: string;
  sho_cd: string;
  sho_name: string;
  naiyoryo: number;
  naiyounit: string;
  packno: number;
  packirisu: number;
  packunit: string;
  packunitmei: string;
  tanka: number;
  suryo: number;
  kingaku: number;
  zaiko_kbn: number;
  seq: number;
  can_be_updated: number;
  can_be_deleted: number;
}

type OrderDetail = {
  hchu_detail_kensu: number;
  hchu_detail_data: Hchu_detail_data[]
}


export const OrderDetailHistory = memo(() => {

  const { showBoundary } = useErrorBoundary();
  const { getIndexData } = useGetIndexData();

  const navigate = useNavigate();
  const location = useLocation();

  // ページトップへ移動する設定
  const { watchScroll, showButton } = usePageTopScroll();

  // メッセージ表示
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [messageBoxKbn, setMessageBoxKbn] = useState(0);
  // メッセージボックスに表示するメッセージ
  const [alertMessage, setAlertMessage] = useState("");

  // ローカルストレージ関連
  // ログインデータ
  const { login } = useAuthContext();

  // カート情報登録API
  const { addCart } = useAddCarts();

  // 発注明細履歴一覧データ
  const [orderDetailHistory, setOrderDetailHistory] = useState<OrderDetail>();

  // 発注情報取得API
  const { getOrderList, orderList } = useGetOrderList()
  // 処理区分
  const [shoriKbn, setShoriKbn] = useState(0);

  // 発注情報削除API
  const { orderDelete } = useDeleteOrder();
  const [deleteDenNo, setDeleteDenNo] = useState(0);

  // 得意先取得API
  const { getTokuisaki, tokuisakiData } = useGetTokuisakiList();

  // カート情報取得API
  const { getCartData, carts } = useGetCarts();
  // 商品単価取得API
  const { getTanka, shohinTanka, setCartTanka } = useGetTanka();

  //　発注明細履歴一覧取得リクエストパラメータ
  const [requestGetOrderDetailHistory, setRequestGetOrderDetailHistory] = useState<RequestGetOrderDetailHistory>({
    login_id: "",
    hchu_date_from: "",
    hchu_date_to: "",
    nou_date_from: "",
    nou_date_to: "",
    sho_name: "",
    tok_cd_from: 0,
    tok_cd_to: 0,
    tan_tok_flg: 0,
  })

  // チェックした明細の伝票番号
  const [targetDenNo, setTargetDenNO] = useState<Array<number>>([])

  // CSVファイルダウンロード処理
  const { downloadCSV, downloadZip } = useDownloadCSV();

  // 発注日（開始）初期値取得
  const today = new Date();   // 当日日付取得
  const hchuDate = today;
  hchuDate.setMonth(today.getMonth() - 1);    // 前月同日の日付データを取得
  // 取得した日付のスタイルを変更（yyyy-MM-dd）
  const hachuDateFr =
    `${hchuDate.getFullYear()}-${(hchuDate.getMonth() + 1).toString().padStart(2, '0')}-${hchuDate.getDate().toString().padStart(2, '0')}`

  // 検索項目入力チェック用
  // 発注日（開始）
  const [isBadInputHachuDateFr, setIsBadInputHachuDateFr] = useState(false);
  // 発注日（終了）
  const [isBadInputHachuDateTo, setIsBadInputHachuDateTo] = useState(false);
  // 納品日（開始）
  const [isBadInputNouDateTo, setIsBadInputNouDateTo] = useState(false);
  // 納品日（終了）
  const [isBadInputNouDateFr, setIsBadInputNouDateFr] = useState(false);

  // お知らせ情報取得
  const { getInfoUnreadList, infoUnreadList } = useGetInfo();
  // お知らせ画面開閉用
  const {
    isOpen: isInfoOgen,
    onOpen: onInfoOpen,
    onClose: onInfoClose,
  } = useDisclosure();


  // スクロールイベントを拾う処理
  useEffect(() => {
    window.addEventListener("scroll", watchScroll);
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);


  // 発注明細履歴一覧取得API
  const getOrderDetailHistory = useCallback(async (request: RequestGetOrderDetailHistory) => {
    await ApiClient.post<OrderDetail>("api/hchulog/detail/", request)
      .then((response) => {
        // console.log(response);
        setOrderDetailHistory(response.data);
      })
      .catch((error) => {
        // console.log(error);

      })
  }, []);

  useEffect(() => {
    // ログイン時、未読のお知らせがある場合のみ表示する
    if (infoUnreadList) {
      onInfoOpen()
    }
  }, [infoUnreadList])

  // 初回レンダリング
  useEffect(() => {
    (async () => {

      // 最新状態保持用
      getIndexData();

      // console.log(hachuDateFr)
      // console.log(localStorage)
      try {
        if (!login) return;

        setRequestGetOrderDetailHistory({
          login_id: login.login_id,   // ログインデータ
          hchu_date_from: hachuDateFr,     // 前月同日
          hchu_date_to: "",
          nou_date_from: "",
          nou_date_to: "",
          sho_name: "",
          tok_cd_from: 0,
          tok_cd_to: 999999,
          tan_tok_flg: login.login_data.admin_kbn === 1 ? 1 : 0,
        })

        // 管理者ログイン時に未読のお知らせ情報を取得。お知らせがある場合、お知らせ画面を表示する
        if (login.login_data.admin_kbn === 1
          && location.state
          && location.state.login === true) {
          await getInfoUnreadList({ login_id: login.login_id })
        }

        await getOrderDetailHistory({
          login_id: login.login_id,
          hchu_date_from: hachuDateFr,
          hchu_date_to: "",
          nou_date_from: "",
          nou_date_to: "",
          sho_name: "",
          tok_cd_from: 0,
          tok_cd_to: 999999,
          tan_tok_flg: login.login_data.admin_kbn === 1 ? 1 : 0,
        })

        // ログインデータが管理者の場合実施
        if (login.login_data.admin_kbn === 1) {
          await getTokuisaki({ login_id: login.login_id });
        }

      } catch (e) {
        showBoundary(e);
      }
    })();
  }, []);

  // 再注文の場合カート情報を更新してからページ遷移する
  useEffect(() => {
    (async () => {
      if (shohinTanka) {
        await setCartTanka(shohinTanka, carts)

        navigate("/OrderConfirmation", { state: { shoriKbn: ShoriKbn.Add } });
      }
    })()
  }, [shohinTanka])


  useEffect(() => {
    (async () => {
      try {
        if (!shoriKbn || shoriKbn === 0) {
        } else {
          if (!login) return;

          if (orderList) {
            // カート内容の作成(カート情報更新時に渡すリクエストパラメータ)
            const newCartData = orderList.hchu_data.map((shohin) => ({
              login_id: login.login_id,    // ログイン時に取得しているデータから持ってくる
              den_no: shoriKbn === ShoriKbn.Update ? shohin.den_no : 0,             // 修正：修正する伝票番号、再注文（新規）：0
              biko: shoriKbn === ShoriKbn.Update ? shohin.biko : "",                // 修正：修正データ、再注文（新規）：クリア
              sho_cd: shohin.sho_cd,
              sho_name: shohin.sho_name,
              naiyoryo: shohin.naiyoryo,
              naiyounit: shohin.naiyounit,
              packno: shohin.packno,
              packirisu: shohin.packirisu,
              packunit: shohin.packunit,
              packunitmei: shohin.packunitmei,
              tanka: shohin.tanka,
              suryo: shohin.suryo,
              zaiko_kbn: shohin.zaiko_kbn,
            }));

            const cartRequest: RequestAddCart = {
              cart_data: newCartData,
            }

            if (cartRequest.cart_data.length > 0) {
              // カート情報更新
              await addCart(cartRequest);

              // ローカルストレージをクリア
              localStorage.removeItem('storedData');

              if (shoriKbn === ShoriKbn.Update) {
                // 修正ボタン押下の場合、ページ遷移時に納品日等を引数で渡すので渡す引数をセット

                navigate("/OrderConfirmation", {
                  state: {
                    shoriKbn: shoriKbn,
                    hachuData: {
                      denNo: newCartData[0].den_no,
                      nouDate: orderList.hchu_data[0].nou_date,
                      biko: newCartData[0].biko
                    }
                  }
                });

              } else if (shoriKbn === ShoriKbn.Add) {
                // 再注文ボタン押下の場合、納品可能日で単価を再取得する
                await getCartData({ login_id: login.login_id })

                getTanka({ login_id: login.login_id, nou_date: login.login_data.nou_kano_date })
              }

            }
          } else {
            setMessageBoxKbn(MessageBoxKbn.Warning);
            setAlertMessage("修正伝票データがありません。");
            onOpen();
          }
          // 処理区分を初期化
          setShoriKbn(0)
        }
      } catch (e) {
        showBoundary(e)
      }
    })()
  }, [shoriKbn])

  // カート情報登録処理(修正又は再注文ボタン押下時)
  const AddCartData = async (denNo: number, shoriKbn: number) => {
    try {
      if (!login) return;

      // 処理区分が新規または修正の時にカート情報を登録する
      if (shoriKbn === ShoriKbn.Delete || shoriKbn === ShoriKbn.Refer) return;

      // 発注情報を取得
      await getOrderList({ login_id: login.login_id, den_no: denNo });
      // 処置区分をセット
      setShoriKbn(shoriKbn);

    } catch (e) {
      showBoundary(e);
    }
  }

  // 発注日入力チェック
  const hachuDateValidation = (hachuDateFr: string, hachuDateTo: string) => {
    const validDateFr = new Date(hachuDateFr)
    const validDateTo = new Date(hachuDateTo)

    // 入力チェック
    if (isBadInputHachuDateFr) {
      throw Error("BadInputHachuDateFr")
    } else if (isBadInputHachuDateTo) {
      throw Error("BadInputHachuDateTo")
    }
    // 大小チェック
    if (validDateFr > validDateTo) {
      throw Error("hachuDate")
    } else {
      return;
    }
  }

  // 納品日入力チェック
  const nouDateValidation = (nouDateFr: string, nouDateTo: string) => {
    const validDateFr = new Date(nouDateFr)
    const validDateTo = new Date(nouDateTo)

    // 入力チェック
    if (isBadInputNouDateFr) {
      throw Error("BadInputNouDateFr")
    } else if (isBadInputNouDateTo) {
      throw Error("BadInputNouDateTo")
    }
    // 大小チェック
    if (validDateFr > validDateTo) {
      throw Error("nouDate")
    } else {
      return;
    }
  }

  const onClickSearch = () => {
    // console.log("onClickSearch");
    // console.log(isBadInputHachuDateFr);
    try {
      // 発注日入力チェック
      hachuDateValidation(requestGetOrderDetailHistory.hchu_date_from, requestGetOrderDetailHistory.hchu_date_to);
      // 納品日入力チェック
      nouDateValidation(requestGetOrderDetailHistory.nou_date_from, requestGetOrderDetailHistory.nou_date_to);

      // 明細履歴一覧再取得
      getOrderDetailHistory(requestGetOrderDetailHistory)

    } catch (e) {
      if (e instanceof Error) {
        switch (e.message) {
          case 'hachuDate':
            setAlertMessage("検索条件の発注日（開始）は発注日（終了）より過去の日付を入力してください。");
            break;
          case 'nouDate':
            setAlertMessage("検索条件の納品日（開始）は納品日（終了）より過去の日付を入力してください。");
            break;
          case 'BadInputHachuDateFr':
            setAlertMessage("検索条件の発注日（開始）が有効な日付ではありません。");
            break;
          case 'BadInputHachuDateTo':
            setAlertMessage("検索条件の発注日（終了）が有効な日付ではありません。");
            break;
          case 'BadInputNouDateFr':
            setAlertMessage("検索条件の納品日（開始）が有効な日付ではありません。");
            break;
          case 'BadInputNouDateTo':
            setAlertMessage("検索条件の納品日（終了）が有効な日付ではありません。");
            break;
          default:
            showBoundary(e);
            break;
        }
        setMessageBoxKbn(MessageBoxKbn.Warning);
        onOpen();
      } else {
        showBoundary(e);
      }
    }
  };

  // 確認メッセージのOKボタン押下時処理
  const onClickConfirmationOK = async () => {
    // console.log("onClickConfirmationOK");
    // console.log(deleteDenNo);

    try {
      if (!login) return;

      // 発注情報削除API
      await orderDelete({
        login_id: login.login_id,
        den_no: deleteDenNo
      });

      // 一覧再読み込み
      await getOrderDetailHistory(requestGetOrderDetailHistory);

      // 削除伝票番号をクリア
      setDeleteDenNo(0);

      // 完了メッセージ表示
      setMessageBoxKbn(MessageBoxKbn.Infomation);
      setAlertMessage("伝票を削除しました。");
      onOpen()
    } catch (e) {
      showBoundary(e);
    }
  }

  // 修正ボタン押下時処理
  const onClickUpdate = async (denNo: number) => {
    // console.log("onClickUpdate")
    try {
      AddCartData(denNo, ShoriKbn.Update);
    } catch (e) {
      showBoundary(e);
    }
  };

  // 削除ボタン押下時処理
  const onClickDelete = (denNo: number) => {
    // console.log("onClickDelete")
    // console.log(denNo);
    try {
      // 削除伝票番号をセット
      setDeleteDenNo(denNo)

      // 確認メッセージ表示
      setMessageBoxKbn(MessageBoxKbn.Confirmation)
      setAlertMessage(`伝票番号：${denNo}を削除します。\nよろしいですか？`)
      onOpen();
    } catch (e) {
      showBoundary(e);
    }
  };

  // 再注文ボタン押下時処理
  const onClickReorder = (denNo: number) => {
    // console.log("onClickReorder")
    // console.log(denNo);
    try {
      AddCartData(denNo, ShoriKbn.Add);
    } catch (e) {
      showBoundary(e);
    }
  }

  // ファイルダウンロードボタン押下時処理
  const onClickFile = async () => {
    // console.log("onClickFile")
    try {
      // console.log(targetDenNo)

      // チェックした伝票がない、または履歴明細データが存在しない場合はメッセージを表示
      if (targetDenNo.length === 0 || !orderDetailHistory) {
        setAlertMessage("CSVダウンロード対象を選択してください。");
        setMessageBoxKbn(MessageBoxKbn.Warning);
        onOpen();

        return;
      }

      if (targetDenNo.length === 1) {
        // チェック項目が一つの場合の処理

        // 選択データを取得
        const selectData = orderDetailHistory.hchu_detail_data.filter((data) => data.den_no === targetDenNo[0])
        // console.log(selectData)

        // チェックしたデータからファイル名を作成
        const filename = `${selectData[0].nou_date}-${selectData[0].hchu_date}-${selectData[0].sfx}-${selectData[0].login_id}-tok.csv`

        // チェックしたデータから出力内容を作成
        // ※バッククォテーションの中で改行すると文字列の改行として認識されるので出力先で予期せぬ改行を防ぐために一列で記述（改行したい箇所には「\n」を記述すること）
        const selectCsvData = selectData.map((data) => (
          `\n${data.sho_cd},${data.sho_name},${data.packno},${data.packirisu},${Number(data.naiyoryo.toFixed(3)).toLocaleString(undefined, { minimumFractionDigits: 3 })}${data.naiyounit},${data.suryo},${data.packunitmei},${data.tanka},${data.kingaku},${data.zaiko_kbn}`
        ))

        // 出力したいデータを作成
        const csvData = `商品コード,商品名,荷姿区分,入数,内容量,数量,単位,単価,金額,在庫区分,${selectCsvData}`

        // CSVファイルをダウンロード
        downloadCSV(csvData, filename);
      } else {
        // 複数選択の場合

        // 現在の日付、時間を取得
        const now = new Date();
        // 保存するフォルダ名をセット(zipフォルダの名称)
        const foldername = `csv-${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.zip`

        // 選択した伝票番号と一致するデータを取得
        const selectData = orderDetailHistory.hchu_detail_data.filter((data) => targetDenNo.find((denNo) => denNo === data.den_no))
        // console.log(selectData)

        // 選択データからファイル名を作成（伝票番号が同じ場合は空文字をセットする※同一のファイル名が作成されることを防ぐ）
        const filename = selectData.map((data, index, array) => {
          const previous = index > 0 ? array[index - 1] : null;
          if (data.den_no !== previous?.den_no) {
            return (
              `${data.nou_date}-${data.hchu_date}-${data.sfx}-${data.login_id}-tok.csv`
            );
          } else {
            return ("");
          }
        });

        // console.log(filename)

        // CSVのデータを抽出
        const data: Array<FileData> = selectData.map((data) => ({
          filename: `${data.nou_date}-${data.hchu_date}-${data.sfx}-${data.login_id}-tok.csv`,
          data: `\n${data.sho_cd},${data.sho_name},${data.packno},${data.packirisu},${Number(data.naiyoryo.toFixed(3)).toLocaleString(undefined, { minimumFractionDigits: 3 })}${data.naiyounit},${data.suryo},${data.packunitmei},${data.tanka},${data.kingaku},${data.zaiko_kbn}`
        }))

        // console.log(data)

        // 複数の商品を注文している場合、CSV出力内容を結合する
        const fileData: Array<FileData> = filename.filter((data) => data !== "").map((item) => ({
          filename: item,
          data: `商品コード,商品名,荷姿区分,入数,内容量,数量,単位,単価,金額,在庫区分,${data.filter((data) => data.filename === item).map((data) => data.data).join(",")}`
        }))

        // console.log(fileData)

        downloadZip(fileData, foldername)
      }
    } catch (e) {
      showBoundary(e);
    }
  };


  // 発注日（開始）入力処理
  const onChangeHchuDateFr = (e: ChangeEvent<HTMLInputElement>) => {
    try {

      setRequestGetOrderDetailHistory({
        ...requestGetOrderDetailHistory,
        hchu_date_from: e.target.value,
      })
    } catch (e) {
      showBoundary(e);
    }
  }

  // 発注日（開始）フォーカスが離れるときの処理
  // ※Changeイベントでの入力チェックでは未入力時と入力途中の区別ができないため
  const onBlurHchuDateFr = (e: FocusEvent<HTMLInputElement>) => {
    try {
      setIsBadInputHachuDateFr(e.target.validity.badInput);

    } catch (e) {
      showBoundary(e);
    }
  }

  // 発注日（終了）入力処理
  const onChangeHchuDateTo = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setRequestGetOrderDetailHistory({
        ...requestGetOrderDetailHistory,
        hchu_date_to: e.target.value,
      })
    } catch (e) {
      showBoundary(e);
    }
  }

  // 発注日（開始）フォーカスが離れるときの処理
  const onBlurHchuDateTo = (e: FocusEvent<HTMLInputElement>) => {
    try {
      setIsBadInputHachuDateTo(e.target.validity.badInput);

    } catch (e) {
      showBoundary(e);
    }
  }

  // 納品日（開始）入力処理
  const onChangeNouDateFr = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setRequestGetOrderDetailHistory({
        ...requestGetOrderDetailHistory,
        nou_date_from: e.target.value,
      })
    } catch (e) {
      showBoundary(e);
    }
  }

  // 納品日（開始）フォーカスが離れるときの処理
  const onBlurNouDateFr = (e: FocusEvent<HTMLInputElement>) => {
    try {
      setIsBadInputNouDateFr(e.target.validity.badInput);

    } catch (e) {
      showBoundary(e);
    }
  }

  // 納品日（終了）入力処理
  const onChangeNouDateTo = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setRequestGetOrderDetailHistory({
        ...requestGetOrderDetailHistory,
        nou_date_to: e.target.value,
      })
    } catch (e) {
      showBoundary(e);
    }
  }

  // 納品日（開始）フォーカスが離れるときの処理
  const onBlurNouDateTo = (e: FocusEvent<HTMLInputElement>) => {
    try {
      setIsBadInputNouDateTo(e.target.validity.badInput);

    } catch (e) {
      showBoundary(e);
    }
  }

  // 商品名入力処理
  const onChangeShohinMei = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setRequestGetOrderDetailHistory({
        ...requestGetOrderDetailHistory,
        sho_name: e.target.value,
      })
    } catch (e) {
      showBoundary(e);
    }
  }

  // 得意先コンボ変更処理
  const onChangeTokuisaki = (e: ChangeEvent<HTMLSelectElement>) => {
    try {
      setRequestGetOrderDetailHistory({
        ...requestGetOrderDetailHistory,
        tok_cd_from: Number(e.target.value),
        tok_cd_to: Number(e.target.value),
      })
    } catch (e) {
      showBoundary(e);
    }
  };

  // 担当分チェックボックスチェック処理
  const onChangeTantoFlag = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const targetData = e.target.checked
      if (targetData) {
        setRequestGetOrderDetailHistory({
          ...requestGetOrderDetailHistory,
          tan_tok_flg: 1,
        })
      } else {
        setRequestGetOrderDetailHistory({
          ...requestGetOrderDetailHistory,
          tan_tok_flg: 0,
        })
      }
    } catch (e) {
      showBoundary(e);
    }
  }

  // チェックボックス変更処理
  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>, denNo: number) => {
    try {
      const targetData = e.target.checked

      if (!targetData) {
        // チェックが外れた伝票番号を含む要素を削除
        setTargetDenNO(targetDenNo.filter((data) => data !== denNo))
      } else {
        // チェックされた伝票番号を追加
        setTargetDenNO([
          ...targetDenNo,
          denNo,
        ])
      };
    } catch {
      showBoundary(e);
    }
  }

  return (
    <>
      <Stack m={{ base: 2, md: 4 }}>
        <Heading as="h1" size="md" textAlign="left">
          発注明細一覧
        </Heading>

        <Box display={{ "2xl": "flex" }} mt={{ base: "10px", xl: "0px", "2xl": "10px" }}>
          <Box display={{ xl: "flex" }}>
            <Flex align="flex-end">
              {/* 発注日From */}
              <Box mr={{ base: "5px", sm: "10px" }}>
                <Text size="xs">発注日</Text>
                <DateInput
                  onChange={onChangeHchuDateFr}
                  onBlur={onBlurHchuDateFr}
                  defaultValue={hachuDateFr}
                />
              </Box>

              <Text pb={2} pr={{ base: "5px", sm: "10px" }} fontSize={{ base: "lg", sm: "xl" }}>
                ～
              </Text>

              {/* 発注日To */}
              <Box mr="35px">
                <DateInput
                  onChange={onChangeHchuDateTo}
                  onBlur={onBlurHchuDateTo}
                />
              </Box>
            </Flex>

            <Flex align="flex-end" mt={{ base: "10px", '2xl': "0px" }}>
              {/* 納品日From */}
              <Box mr={{ base: "5px", sm: "10px" }}>
                <Text size="xs">納品日</Text>
                <DateInput
                  onChange={onChangeNouDateFr}
                  onBlur={onBlurNouDateFr}
                />
              </Box>
              <Text pb={2} pr={{ base: "5px", sm: "10px" }} fontSize={{ base: "lg", sm: "xl" }}>
                ～
              </Text>
              {/* 納品日To */}
              <Box mr="35px">
                <DateInput
                  onChange={onChangeNouDateTo}
                  onBlur={onBlurNouDateTo}
                />
              </Box>
            </Flex>
          </Box>
          <Flex align="flex-end" justifyContent={{ base: "space-between", sm: "normal" }}>

            <Box
              mr={{ base: "35px", xl: "470px", "2xl": "35px" }}
              w={{ base: "auto", sm: "265px" }}
              mt={{ base: "10px", '2xl': "0px" }}>
              <Text size="xs">商品名</Text>
              <Input onChange={onChangeShohinMei} />
            </Box>

            {/* 検索ボタン */}
            <Flex display={login && login.login_data.admin_kbn !== 1 ? "" : "none"} pb="4px" mt="25px" mr="4px">
              <PrimaryButton onClick={onClickSearch}
                bgGradient="linear(blue.400, blue.600)"
                color="white" width={100} icon={<Icon as={BsSearch} mt="3px" boxSize="14px" />}>
                検索
              </PrimaryButton>
            </Flex>

          </Flex>
        </Box>

        <Box
          display={{ lg: "flex" }}
          mt={login && login.login_data.admin_kbn === 1 ? "10px" : ""}>
          <Flex align="flex-end">
            {/* 得意先コンボボックス */}
            {/* ログインデータが管理者の場合表示 */}
            <Box
              display={login && login.login_data.admin_kbn === 1 ? "" : "none"}
              w={{ base: "auto", md: "400px" }}
              maxW="400px"
              minW="200px"
              mr={8}
            >
              <Text size="xs">得意先</Text>
              <Select onChange={onChangeTokuisaki} defaultValue="">
                <option value=""></option>
                {tokuisakiData.map((data) => (
                  <option key={data.tok_cd} value={data.tok_cd}>{data.tok_cd}：{data.tok_mei}</option>
                ))}
              </Select>
            </Box>

            {/* 担当分チェックボックス */}
            {/* ログインデータが管理者の場合表示 */}
            <Stack
              display={login && login.login_data.admin_kbn === 1 ? "" : "none"}
              minW="65px"
              align="center"
              mb="14px"
              mr={{ md: "15px", "2xl": "540px" }}
            >
              <Text size="xs">担当分</Text>
              <Checkbox
                defaultChecked={login && login.login_data.admin_kbn === 1 ? true : false}
                onChange={onChangeTantoFlag}
                pt="10px"
                pl="15px"
              />
            </Stack>

          </Flex>

          {/* 検索ボタン */}
          {/* ログインデータが管理者の場合表示 */}
          <Box display={login && login.login_data.admin_kbn === 1 ? "" : "none"} textAlign="right" mt="25px" mr="4px">
            <PrimaryButton onClick={onClickSearch}
              bgGradient="linear(blue.400, blue.600)"
              color="white" width={100} icon={<Icon as={BsSearch} boxSize="14px" />}>
              検索
            </PrimaryButton>
          </Box>
        </Box>

        <Text mt="10px">発注履歴検索が完了しました。{orderDetailHistory?.hchu_detail_kensu}件該当しました。</Text>

        <Flex mt="10px">
          {/* ページネーション */}

          <Spacer />
          <PrimaryButton
            onClick={onClickFile}
            bgGradient="linear(teal.400, teal.600)"
            color="white"
            display={login && login.login_data.admin_kbn === 1 ? "none" : ""}
            icon={<Icon as={BsDownload} boxSize="16px" color='white' />}
            margin={1}
            width={220}
          >ファイルダウンロード</PrimaryButton>
        </Flex>

        <TableContainer overflowX={{ base: "scroll", md: "auto" }} w="100%" whiteSpace="normal">
          <Table size="sm">
            <Thead bg="#006DA7">
              <Tr>
                <Th>発注日</Th>
                <Th>納品日</Th>
                <Th colSpan={2}>伝票番号</Th>
                <Th colSpan={6} pr="44px" textAlign="right">{login && login.login_data.admin_kbn === 1 ? "" : "DL"}</Th>
              </Tr>
              <Tr>
                <Th colSpan={4}>商品名</Th>
                <Th maxW="70px" minW="70px" rowSpan={2}>数量</Th>
                <Th minW="70px" rowSpan={2}>単位</Th>
                <Th minW="118px" rowSpan={2}>商品コード</Th>
                <Th minW="120px" rowSpan={2}>ログインID</Th>
                <Th minW="135px" rowSpan={2}>得意先コード</Th>
                <Th minW="170px" rowSpan={2}>得意先名</Th>
              </Tr>
              <Tr>
                <Th minW="111px">内容量</Th>
                <Th minW="111px">入数</Th>
                <Th minW="111px">単価</Th>
                <Th minW="115px">金額</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orderDetailHistory?.hchu_detail_data.map((data, index, array) => {
                const previous = index > 0 ? array[index - 1] : null;

                if (data.den_no !== previous?.den_no) {

                  return (
                    <Fragment key={data.key_no}>
                      <Tr bg="#D4E5EF">
                        <Td>{data.hchu_date}</Td>
                        <Td>{data.nou_date}</Td>
                        <Td colSpan={2}>{data.den_no}</Td>
                        <Td
                          colSpan={6}
                          textAlign="right"
                          w={{ base: "auto", md: "60%" }}
                        >
                          <SecondaryButton
                            onClick={() => onClickUpdate(data.den_no)}
                            bgGradient='linear(#6F94CD, #006AB7)'
                            color="white"
                            isDisabled={data.can_be_updated === 0 ? true : false}
                            width={80}
                          >修正</SecondaryButton>
                          <SecondaryButton
                            onClick={() => onClickDelete(data.den_no)}
                            bgGradient='linear(red.300, red.500)'
                            color="white"
                            isDisabled={data.can_be_deleted === 0 ? true : false}
                            margin={2}
                            width={80}
                          >削除</SecondaryButton>
                          <SecondaryButton
                            onClick={() => onClickReorder(data.den_no)}
                            bgGradient='linear(teal.400, teal.600)'
                            color="white"
                            isDisabled={login && login.login_data.admin_kbn === 1 ? true : false}
                            width={90}
                          >再注文</SecondaryButton>
                          <Checkbox
                            onChange={(e) => onChangeCheckbox(e, data.den_no)} bg="white"
                            display={login && login.login_data.admin_kbn === 1 ? "none" : "inline-flex"}
                            mx={10}
                            mt={4}
                          />
                        </Td>
                      </Tr>
                      <Tr>
                        <Td colSpan={4} textAlign="left">{data.sho_name}</Td>
                        <Td textAlign="right" rowSpan={2}>{data.suryo}</Td>
                        <Td rowSpan={2}>{data.packunitmei}</Td>
                        <Td rowSpan={2}>{data.sho_cd}</Td>
                        <Td rowSpan={2}>{data.login_id}</Td>
                        <Td rowSpan={2}>{data.tokcd}</Td>
                        <Td rowSpan={2} textAlign="left">{data.tokmei}</Td>
                      </Tr>
                      <Tr>
                        <Td textAlign="right">
                          {data.naiyoryo === 0 ? "" : `${Number(data.naiyoryo.toFixed(3)).toLocaleString(undefined, { minimumFractionDigits: 3 })}${data.naiyounit}`}
                        </Td>
                        <Td textAlign="right">{data.packirisu}</Td>
                        <Td textAlign="right">{data.tanka.toLocaleString()}円</Td>
                        <Td textAlign="right">{Math.round(data.kingaku).toLocaleString()}円</Td>
                      </Tr>
                    </Fragment>
                  )
                } else {
                  return (
                    <Fragment key={data.key_no}>
                      <Tr>
                        <Td colSpan={4} textAlign="left">{data.sho_name}</Td>
                        <Td textAlign="right" rowSpan={2}>{data.suryo}</Td>
                        <Td rowSpan={2}>{data.packunitmei}</Td>
                        <Td rowSpan={2}>{data.sho_cd}</Td>
                        <Td rowSpan={2}>{data.login_id}</Td>
                        <Td rowSpan={2}>{data.tokcd}</Td>
                        <Td rowSpan={2} textAlign="left">{data.tokmei}</Td>
                      </Tr>
                      <Tr>
                        <Td textAlign="right">
                          {data.naiyoryo === 0 ? "" : `${Number(data.naiyoryo.toFixed(3)).toLocaleString(undefined, { minimumFractionDigits: 3 })}${data.naiyounit}`}
                        </Td>
                        <Td textAlign="right">{data.packirisu}</Td>
                        <Td textAlign="right">{data.tanka.toLocaleString()}円</Td>
                        <Td textAlign="right">{Math.round(data.kingaku).toLocaleString()}円</Td>
                      </Tr>
                    </Fragment>
                  )
                }
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack >

      <MessageBox
        isOpen={isOpen}
        onClose={onClose}
        onClickConfirmation={() => onClickConfirmationOK()}
        cancelRef={cancelRef}
        messageBoxKbn={messageBoxKbn}
        alertMessage={alertMessage}
      />

      <PageTopScrollButton showButton={showButton} />

      <ModalInfo isOpen={isInfoOgen} onClose={onInfoClose} unreadKbn={true} data={infoUnreadList?.info_list_data} />
    </>
  )
})

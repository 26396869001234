import { useCallback, useState } from 'react'

import { OrderList, OrderListRequest } from '../types/OrderType';
import { ApiClient } from '../utility/Utility';

// 発注情報を取得するカスタムフック
export const useGetOrderList = () => {

  const [orderList, setOrderList] = useState<OrderList | null>(null);

  // 発注情報取得API
  const getOrderList = useCallback(async (request: OrderListRequest) => {
    //console.log(request);

    await ApiClient.post('api/hchu/list/', request)
      .then((response) => {
        //console.log(response);
        setOrderList(response.data)
      })
      .catch((error) => {
        //console.log(error);
      });

  }, []);

  return { orderList, getOrderList }
}

import { useCallback } from 'react'
import { ApiClient } from '../utility/Utility'

export const useLogout = () => {

  const logout = useCallback(async (request: { login_id: string }) => {
    await ApiClient.post("api/accounts/logout/", request)
      .then((response) => {

      })
      .catch((error) => {
        // console.error(error)
      })
  }, [])
  return { logout }
}

import { useCallback, useState } from 'react'

import { InfoList } from '../types/InfoType';
import { ApiClient } from '../utility/Utility';

// お知らせ情報を取得するカスタムフック
export const useGetInfo = () => {
  const [infoList, setInfoList] = useState<InfoList | null>(null);
  const [infoUnreadList, setInfoUnreadList] = useState<InfoList | null>(null);

  // お知らせ情報取得
  const getInfoList = useCallback(async () => {
    await ApiClient.post('api/info/list/')
      .then((response) => {
        // console.log(response.data);
        setInfoList(response.data)
      })
      .catch((error) => {
        //console.log(error);
      });
  }, [])

  // お知らせ情報取得（ログインユーザーが未読のお知らせを取得）
  const getInfoUnreadList = useCallback(async (request: { login_id: string }) => {
    await ApiClient.post('api/info/unread_list/', request)
      .then((response) => {
        // console.log(response);
        setInfoUnreadList(response.data)
      })
      .catch((error) => {
        //console.log(error);
      });
  }, [])

  return { getInfoList, getInfoUnreadList, infoList, infoUnreadList, setInfoUnreadList }
}

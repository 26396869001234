import React, { FC, ReactNode, memo, useCallback } from 'react'

import { useNavigate } from 'react-router-dom';

import { Box, useDisclosure } from '@chakra-ui/react';

import { Footer } from '../../atoms/Footer';
import { Header } from '../../atoms/Header';
import { MenuDrawer } from '../../atoms/MenuDrawer';
import { SideBar } from '../../atoms/SideBar';
import { useAuthContext } from '../../../providers/AuthProvider';
import { useLogout } from '../../../hooks/useLogout';
import { useErrorBoundary } from 'react-error-boundary';

type Props = {
  children: ReactNode;
};

export const Layout: FC<Props> = memo((props) => {
  const { children } = props;

  const { showBoundary } = useErrorBoundary();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { signout, login } = useAuthContext();
  const { logout } = useLogout();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickLogOut = useCallback(async () => {
    try {
      if (!login) return;

      await logout({ login_id: login.login_id })
      signout();
      navigate("/");
    } catch (e) {
      showBoundary(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickOrder = useCallback(() => navigate("/Order"), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickStandardOrder = useCallback(() => navigate("/StandardOrder"), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickOrderDetailHistory = useCallback(() => navigate("/OrderDetailHistory"), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickOrderHistory = useCallback(() => navigate("/OrderHistory"), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickStandardItem = useCallback(() => navigate("/StandardItem"), []);

  return (
    <>
      <Header onOpen={onOpen} onClickLogOut={onClickLogOut} />
      <SideBar
        onClickOrderDetailHistory={onClickOrderDetailHistory}
        onClickOrderHistory={onClickOrderHistory}
        onClickStandardItem={onClickStandardItem}
        onClickOrder={onClickOrder}
        onClickStandardOrder={onClickStandardOrder}
      />
      <Box
        ml={{ base: "0", md: "250px" }}
        zIndex={0}
        position="relative"
        pt="92px"
        pb="35px"
        minH="100vh"
      >
        {children}
      </Box>
      <Footer />
      <MenuDrawer
        onClose={onClose}
        isOpen={isOpen}
        onClickOrderDetailHistory={onClickOrderDetailHistory}
        onClickOrderHistory={onClickOrderHistory}
        onClickStandardItem={onClickStandardItem}
        onClickOrder={onClickOrder}
        onClickStandardOrder={onClickStandardOrder}
      />
    </>
  )
});

// グロバール関数
import axios from "axios";

// カート情報、発注情報　数量入力範囲
export const MaxRange: number = 9999999;
export const MinRange: number = 0;


// 定番品フラグ
export enum TeibanFlag {
  ShohinIchiran = 0,
  Teibanhin = 1,
}

// 処理区分
export enum ShoriKbn {
  Add = 1,
  Update = 2,
  Delete = 3,
  Refer = 4,
}

// メッセージ区分
export enum MessageBoxKbn {
  Infomation = 1,
  Confirmation = 2,
  Warning = 3,
  Error = 4,
}

// Axiosのインスタンス設定
export const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8000/',
  headers: {},
});

// console.log("axios");
// トークンの認証確認を共通化(Axiosインターセプターを使用)
ApiClient.interceptors.request.use((config) => {
  // トークンが存在する場合はヘッダーに追加
  // config.headers['Authorization'] = '';
  return config;
},
  (error) => {

    return Promise.reject(error);
  }
);

// 文字列の日付をyyyy/mm/ddフォーマットに変換する
export const formattedDate = (strDate: string) => {
  const date = new Date(strDate);

  return date.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' })
}


/* eslint-disable react-hooks/exhaustive-deps */

import React, { FC, memo } from "react";
import { Route, Routes } from "react-router-dom";

import { Page404 } from "../components/pages/Page404";
import { NestRoutes } from "./NestRoutes";
import { Layout } from "../components/pages/templates/Layout";
import { PrivateRoute } from "./PrivateRoute";
import { AxiosErrorHandling } from "../utility/AxiosErrorHandling";
import { ReactErrorBoundary } from "../utility/ReactErrorBoundary";

export const Router: FC = memo(() => {
  return (
    <Routes>
      <Route path="/">
        {NestRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            // ログイン画面以外の画面ではヘッダー、サイドバーを表示する
            element={
              route.path === "/"
                ? <>{route.chirdren}</>
                : <PrivateRoute
                  element={
                    <AxiosErrorHandling>
                      <Layout>
                        <ReactErrorBoundary>
                          {route.chirdren}
                        </ReactErrorBoundary>
                      </Layout>
                    </AxiosErrorHandling>
                  } />
            }
          />
        ))
        }
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
});
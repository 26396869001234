import { useCallback, useState } from 'react'

import { ApiClient } from "../utility/Utility";
import { ShohinListData } from '../types/ShohinListDataType';

type ShohinList = {
  sho_list_data: ShohinListData[]
}

type Request = {
  login_id: string;
  teban_reg: number;
  teban_flg: number;
  mydaibuncd: string;
  mychubuncd: number;
  sho_name: string;
}

// APIをコールし、商品一覧を取得する
export const useProducts = () => {

  const [shohinList, setShohinList] = useState<Array<ShohinListData>>([]);
  const [shohinResponseStatus, setShohinResponseStatus] = useState(0);

  const getShohinListData = useCallback(async (request: Request) => {
    // console.log("getShohinListData");
    // console.log(request);

    await ApiClient.post<ShohinList>(`api/shohinlist/`, request)
      .then((response) => {
        setShohinList(response.data.sho_list_data);
        setShohinResponseStatus(response.status);
        // console.log(response);
      })
      .catch((error) => {
        // データ取得できなかった場合の処理(Status:400,500番台の処理はcatch処理になる)
        // console.log(error);
        setShohinList([]);
      })

  }, []);

  return { getShohinListData, shohinList, shohinResponseStatus };
};

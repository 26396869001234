import { useCallback, useState } from 'react'

import { ApiClient } from "../utility/Utility";
import { StandardTokItemData, StandardRankItemData } from '../types/StandardItemDataType';

type Request = {
    login_id: string;
}

// APIをコールし、定番品一覧(得意先単位)を取得する
export const useStandardItem = () => {

    const [standardItem, setStandardItem] = useState<StandardTokItemData | null>(null);

    //定番品(得意先単位)を取得
    const getStandardTokItem = useCallback(async (request: Request) => {
        //console.log(request);

        await ApiClient.post<StandardTokItemData>(`api/teban/`, request)
            .then((response) => {
                //　★今後、ここで取得した定番品の中から、定番品マスタに登録済みの商品をチェックされた商品としてセットするような処理を作りたい
                setStandardItem(response.data);
                //console.log(response);
            })
            .catch((error) => {
                // データ取得できなかった場合の処理(Status:400,500番台の処理はcatch処理になる)
                // ★エラーメッセージ等 確認してから実装
                //console.log(error);
                throw error
            })

    }, []);

    const [standardRankItem, setStandardRankItem] = useState<StandardRankItemData | null>(null);

    //定番品(ランク単位)を取得
    const getStandardRankItem = useCallback(async (request: Request) => {
        //console.log(request);

        await ApiClient.post<StandardRankItemData>(`api/tebanrank/`, request)
            .then((response) => {
                //　★今後、ここで取得した定番品の中から、定番品マスタに登録済みの商品をチェックされた商品としてセットするような処理を作りたい
                setStandardRankItem(response.data);
                //console.log(response);
            })
            .catch((error) => {
                //console.log(error);
                throw error
            })

    }, []);

    return { getStandardTokItem, getStandardRankItem, standardItem, standardRankItem };
};
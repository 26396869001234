
import { KeyboardEvent } from 'react'
import { useErrorBoundary } from 'react-error-boundary';

// 数値コントロールで上下矢印キー押下時に値が変更しないようにするカスタムフック
export const useHandleArrowKey = () => {
  const { showBoundary } = useErrorBoundary();

  // 数値入力時のキーボード操作設定
  // ・矢印キーでの数値増減を無効化
  const onkeydownNum = (e: KeyboardEvent<HTMLInputElement>) => {
    try {
      const isArrowKeyPressed = ['ArrowUp', 'ArrowDown']
        .includes(e.key,);

      if (!isArrowKeyPressed) {
        // 矢印キー以外の時は通常処理
        return;
      } else {

        // 矢印キーのデフォルトの操作を無効化
        e.preventDefault();
      }
    } catch (e) {
      showBoundary(e);
    }
  };

  return { onkeydownNum }
}

/* eslint-disable react-hooks/exhaustive-deps */

import React, { ChangeEvent, Fragment, memo, useCallback, useEffect, useRef, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom';

import { useErrorBoundary } from 'react-error-boundary';

import {
  Box,
  Flex,
  Heading,
  Icon,
  NumberInput,
  NumberInputField,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react"

import { BsCartX, BsCartCheck, BsXLg } from "react-icons/bs";

// コンポーネント
import { MessageBoxKbn, ShoriKbn, ApiClient, MaxRange, MinRange } from '../../utility/Utility';
import { PrimaryButton } from '../atoms/buttons/PrimaryButton';
import { SecondaryButton } from '../atoms/buttons/SecondaryButton';
import { PageTopScrollButton } from '../atoms/buttons/PageTopScrollButton';
import { MessageBox } from '../atoms/MessageBox';
import { DateInput } from '../atoms/Input/DateInput';
import { RequestCreateOder, RequestUpdateOrder } from '../../types/OrderType';
import { GetCartData, RequestAddCart, RequestCartDelete } from '../../types/CartDataType';

// カスタムフック
import { useHandleArrowKey } from '../../hooks/useHandleArrowKey';
import { useAddCarts } from '../../hooks/useAddCarts';
import { useDeleteCarts } from '../../hooks/useDeleteCarts';
import { usePageTopScroll } from '../../hooks/usePageTopScroll';
import { useGetCarts } from '../../hooks/useGetCarts';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useDeleteOrder } from '../../hooks/useDeleteOrder';
import { useGetOrderList } from '../../hooks/useGetOrderList';
import { useAuthContext } from '../../providers/AuthProvider';
import { useGetTanka } from '../../hooks/useGetTanka';
import { useGetIndexData } from '../../hooks/useGetIndexData';


// 注文確認画面
export const OrderConfirmation = memo(() => {

  const { showBoundary } = useErrorBoundary();
  const { getIndexData } = useGetIndexData();

  // 遷移元ページから渡ってくる値を表示する
  const location = useLocation();

  // ページ遷移
  const navigate = useNavigate();

  // デバウンス用のタイマー
  const timerRef = useRef(0);

  // 矢印キー押下時処理設定
  const { onkeydownNum } = useHandleArrowKey();

  // ページトップへ移動する設定
  const { watchScroll, showButton } = usePageTopScroll();

  // メッセージ表示
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [messageBoxKbn, setMessageBoxKbn] = useState(0);
  // メッセージの「はい」ボタン押下時の処理区分
  // ※削除ボタン（delete):カート内情報の削除、注文確定ボタン（order）:発注情報登録
  const [messageButtonOnClickKbn, setMessageButtonOnClickKbn] = useState("");
  const [onClickWarningKbn, setOnClickWarningKbn] = useState("");

  // メッセージボックスに表示するメッセージ
  const [alertMessage, setAlertMessage] = useState("");

  // カート情報取得API
  const { carts, setCarts, getCartData } = useGetCarts();

  // カート情報更新API
  const { addCart } = useAddCarts();

  // カート情報削除API
  const { deleteCartData } = useDeleteCarts();

  // 商品単価取得API
  const { getTanka, shohinTanka, setCartTanka } = useGetTanka();

  // 発注情報取得
  const { orderList, getOrderList } = useGetOrderList();

  // 発注情報削除
  const { orderDelete } = useDeleteOrder();

  // カート情報削除用
  const [requestCartDelete, setRequestCartDelete] = useState<RequestCartDelete>({
    login_id: '',
    sho_cd: '',
    packno: 0,
  });

  // 発注情報登録用
  const [create, setCreate] = useState<RequestCreateOder>({
    login_id: '',
    nou_date: '',
    kingaku: 0,
    biko: '',
    hchu_detail_data: []
  });

  // 発注情報更新用
  const [update, setUpdate] = useState<RequestUpdateOrder>({
    login_id: '',
    den_no: 0,
    nou_date: '',
    kingaku: 0,
    biko: '',
    hchu_detail_data: []
  });

  // 数量変更した商品
  const [selectCarts, setSelectCarts] = useState<Array<GetCartData>>([])

  // 納品日　曜日表示用
  const [weekday, setWeekday] = useState("");
  // 曜日取得フラグ(true:曜日を取得、false:なにもしない)
  const [getWeekdayFlg, setGetWeekdayFlg] = useState(false);

  // ログインデータ取得
  const { login } = useAuthContext();

  const { storedData, setStoredData } = useLocalStorage({
    storedDenNo: 0,
    storedNouDate: "",
    storedBiko: "",
  })

  // 納品可能日の最大日を取得
  const today = new Date();   // 当日日付取得
  const hchuDate = today;
  hchuDate.setMonth(today.getMonth() + 1);    // 一か月後の日付
  // 取得した日付のスタイルを変更（yyyy-MM-dd）
  const strMaxDate =
    `${hchuDate.getFullYear()}-${(hchuDate.getMonth() + 1).toString().padStart(2, '0')}-${hchuDate.getDate().toString().padStart(2, '0')}`

  // 入力チェック用
  const maXDate: Date = new Date(strMaxDate);
  const minDate: Date = new Date(login ? login.login_data.nou_kano_date : "");

  // スクロールイベントを拾う処理
  useEffect(() => {
    window.addEventListener("scroll", watchScroll);
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);

  // ローカルストレージの値を削除
  const deleteStored = useCallback(() => {
    localStorage.removeItem('storedData');
  }, [])

  // 曜日取得処理
  const getWeekday = (targetDate: string) => {
    const selectDate: Date = new Date(targetDate);
    const newWeekday = selectDate.toLocaleDateString('ja', { weekday: 'long' });

    setWeekday(newWeekday);
  }

  // API完了メッセージ
  const completeMessage = () => {
    try {
      if (location.state.shoriKbn === ShoriKbn.Add) {
        setMessageButtonOnClickKbn("");
        setAlertMessage("ご注文いただきありがとうございます。");
        setMessageBoxKbn(MessageBoxKbn.Infomation);
        onOpen();
      } else if (location.state.shoriKbn === ShoriKbn.Update) {
        setAlertMessage("伝票を修正しました。");
        setMessageBoxKbn(MessageBoxKbn.Infomation);
        onOpen();
      }
    } catch (e) {
      showBoundary(e);
    }
  }

  // 発注情報登録API
  const setCreateOrder = useCallback(async (request: RequestCreateOder) => {
    // console.log("setCreateOrder");
    // console.log(request);
    await ApiClient.post('api/hchu/create/', request)
      .then((responce) => {
        // console.log(responce);

        // 登録完了メッセージ表示
        completeMessage();

        // ローカルストレージの値を削除
        deleteStored();

      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  // 発注情報更新API
  const setUpdateOrder = useCallback(async (request: RequestCreateOder) => {
    // console.log("setUpdateOrder");
    // console.log(request);

    await ApiClient.put('api/hchu/update/', request)
      .then((responce) => {
        // console.log(responce);
        // console.log(update)

        // 修正完了メッセージ表示
        completeMessage();

        // ローカルストレージの値を削除
        deleteStored();

      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  // カート情報削除処理
  const deleteCarts = useCallback(async (request: RequestCartDelete) => {
    try {
      if (!login) return;

      // 削除APIをコール
      await deleteCartData(request);

      // console.log(carts);

      // カートを空にするボタンまたは削除ボタンを押下時はカート情報を再取得する
      if (messageButtonOnClickKbn === "delete" || messageButtonOnClickKbn === "allDelete") {
        getCartData({ login_id: login.login_id });
      } else {
        return;
      }
    } catch (e) {
      showBoundary(e);
    }
  }, [messageButtonOnClickKbn]);

  // 初回レンダリング時にセットする値
  useEffect(() => {

    if (login) {

      // 最新状態保持用
      getIndexData();

      (async () => {
        try {

          // console.log(localStorage)
          // console.log(location.state.hachuData)

          localStorage.removeItem('state')

          // ローカルストレージの納品日が空の場合は納品日をセットする
          if (!storedData || storedData.storedNouDate === "") {
            if (location.state.shoriKbn === ShoriKbn.Add) {
              setStoredData({
                ...storedData,
                storedNouDate: login.login_data.nou_kano_date
              })

            } else {
              if (location.state.hachuData) {
                setStoredData({
                  storedDenNo: location.state.hachuData.denNo,
                  storedNouDate: location.state.hachuData.nouDate,
                  storedBiko: location.state.hachuData.biko,
                })

                if (location.state.shoriKbn === ShoriKbn.Refer) {
                  localStorage.setItem('state', JSON.stringify(location.state.shoriKbn))
                }
              }
            }

            // 曜日取得フラグ
            // ※このタイミングでgetWeekday関数（曜日取得処理）を記述しない理由はReactの性質上この処理内ではローカルストレージの値が更新されておらず、
            // 　getWeekday関数を使用しても納品日が空白状態で曜日を取得してしまうため、曜日取得フラグを活用したuseEffectを使用して納品日の曜日を取得する。
            setGetWeekdayFlg(true);
          } else {
            // 納品日をセットまたは変更後に別ページに遷移し、再び注文確認画面に戻ってきた場合の処理

            // 曜日取得処理
            getWeekday(storedData.storedNouDate);
          }

          setRequestCartDelete({
            ...requestCartDelete,
            login_id: login.login_id
          })

          // カート情報取得APIをコール
          await getCartData({ login_id: login.login_id });

        } catch (e) {
          showBoundary(e)
        }
      })()
    }
  }, []);

  // 新規、修正、照会での画面遷移時にローカルストレージに納品日の値がない場合、初回レンダリングの処理内で曜日の取得ができないため、
  // 　その場合のみ下記のuseEffectで曜日取得を行う。（発注伝票一覧で修正ボタンを押下して画面遷移してきた場合などが該当）
  useEffect(() => {
    if (getWeekdayFlg) {
      //  曜日取得処理
      getWeekday(storedData.storedNouDate);
      // 曜日取得フラグの初期化
      setGetWeekdayFlg(false);
    }
  }, [getWeekdayFlg])

  // カート内の数量が変わる度に発注情報に登録または更新する値をセットする
  useEffect(() => {
    // console.log("changeCart");
    // console.log(localStorage);

    // ログインデータがない場合以下の処理は実施しない
    if (!login) return;
    // 処理区分が照会の場合は以下処理を実施しなし
    if (location.state.shoriKbn === ShoriKbn.Refer) return;

    // 処理区分が新規、修正の場合でカートの中に商品が入っている場合
    if (carts.length > 0) {

      const newCreateOrder = carts.filter((cart) => cart.suryo > 0).map((cart) => ({
        sho_cd: cart.sho_cd,
        sho_name: cart.sho_name,
        naiyoryo: cart.naiyoryo,
        packno: cart.packno,
        packirisu: cart.packirisu,
        packunit: cart.packunit,
        tanka: cart.tanka,
        suryo: cart.suryo,
        zaiko_kbn: cart.zaiko_kbn,
      }));

      const newKingaku = newCreateOrder.reduce((acc, cart) => acc + Math.round(cart.tanka * cart.suryo), 0);

      // console.log(newCreateOrder)

      // 登録・更新リクエストパラメータに値をセットする
      if (location.state.shoriKbn === ShoriKbn.Add) {
        // console.log(create);

        // 登録
        setCreate({
          ...create,
          login_id: login.login_id,     // ログインデータ
          nou_date: storedData.storedNouDate,   // ログインデータ
          kingaku: newKingaku,
          biko: storedData.storedBiko,
          hchu_detail_data: newCreateOrder,
        });
      } else if (location.state.shoriKbn === ShoriKbn.Update) {
        // console.log(update);

        // 更新
        setUpdate({
          ...update,
          login_id: login.login_id,     // ログインデータ
          nou_date: storedData.storedNouDate,   // ログインデータ
          den_no: storedData.storedDenNo,
          kingaku: newKingaku,
          biko: storedData.storedBiko,
          hchu_detail_data: newCreateOrder,
        })
      } else {
        return;
      };
    } else if (carts.length === 0) {

      if (location.state.shoriKbn === ShoriKbn.Add) {
        // 登録
        setCreate({
          ...create,
          kingaku: 0,
          hchu_detail_data: [],
        });
      } else if (location.state.shoriKbn === ShoriKbn.Update) {
        // console.log(carts)

        // 更新
        setUpdate({
          ...update,
          kingaku: 0,
          hchu_detail_data: [],
        })
      } else {
        return;
      };
    }

  }, [carts]);

  // 入力チェック
  const suryoValidation = (validData: GetCartData[]) => {
    const validCart = validData.filter((data) => data.suryo < MinRange || data.suryo > MaxRange)
    const decimalPlaces = validData.filter((data) => !Number.isInteger(Number(data.suryo)))
    // 数量が0未満又は999999999以上の時エラーメッセージを表示
    if (validCart.length > 0) {
      throw Error('suryo')
    } else if (decimalPlaces.length > 0) {
      throw RangeError('decimalPlaces')
    } else {
      if (carts.length > 0) {
        const validCart2 = carts.filter((data) => Number(data.suryo) === 0)

        // カート内商品の数量が0又は未入力の場合エラーメッセージを表示
        if (validCart2.length > 0) {
          throw Error('noneSuryo')
        } else {
          return;
        }
      }
    }
  }

  const nouDateValidation = (nouDate: string) => {
    const validNouDate = new Date(nouDate);

    if (nouDate === "") {
      throw Error('nouDate')
    } else {
      if (weekday === "日曜日") {
        throw Error('nouDateWeekday')
      } else if (validNouDate < minDate) {
        throw Error('nouDate')
      } else if (validNouDate >= maXDate) {
        throw Error('nouDateMax')
      } else {
        return;
      }
    }
  }

  // 確認メッセージの「はい」を押下した場合の処理
  // (delete: カートを空にするボタンまたは削除ボタン押下時処理。
  //  order: 注文するボタンまたは修正するボタン押下時処理。発注情報の登録または更新処理を実施し発注入力画面に遷移する。
  //  orderCancel: カートが空の状態で修正するボタン押下時処理。修正伝票を削除し発注入力画面に遷移する。
  //  cancel: 修正キャンセルボタン押下時処理。伝票修正をキャンセルし発注入力画面に遷移する。)
  const onClickMessageButton = useCallback(async (props: string) => {
    try {
      if (!login) return;

      if (props === "delete") {

        deleteCarts(requestCartDelete);

      } else if (props === "allDelete") {
        setStoredData({
          ...storedData,
          storedNouDate: login.login_data.nou_kano_date,
          storedBiko: "",
        })

        await deleteCarts(requestCartDelete);

        setGetWeekdayFlg(true);

      } else if (props === "order") {

        if (location.state.shoriKbn === ShoriKbn.Add) {
          // console.log(create);
          // 注文情報登録APIをコール
          setCreateOrder(create);
        } else if (location.state.shoriKbn === ShoriKbn.Update) {
          // console.log(update);
          // 注文情報登録APIをコール
          setUpdateOrder(update);
        }
      } else if (props === "orderCancel") {
        // 発注情報削除
        await orderDelete({
          login_id: login.login_id,
          den_no: storedData.storedDenNo
        })

        //  カート情報削除
        await deleteCarts(requestCartDelete);

        // ローカルストレージの値を削除
        deleteStored();

        // 完了メッセージ表示
        completeMessage();

      } else if (props === "cancel") {
        //  カート情報削除
        await deleteCarts(requestCartDelete);

        // ローカルストレージの値を削除
        deleteStored();

        // 商品一覧ページへ移動
        navigate("/Order");

      }
    } catch (e) {
      showBoundary(e);
    }
  }, [create, update, requestCartDelete]);

  // 情報メッセージの「OK」を押下した場合の処理
  const onClickOk = () => {
    try {
      // カートを空にする
      setCarts([]);
      // 発注入力（商品一覧）ページに遷移
      navigate("/Order");
    } catch (e) {
      showBoundary(e);
    }
  }

  const onClickWarning = (onClickWarningKbn: string) => {
    try {
      if (onClickWarningKbn === "notUpdate") {
        // 発注入力（商品一覧）ページに遷移
        navigate("/Order");
      } else {

      }
    } catch (e) {
      showBoundary(e);
    }
  }

  // キャンセルボタン押下時処理
  const onClickCancel = async () => {
    // console.log("cancel");
    try {
      // カート削除リクエストをセット
      setRequestCartDelete({
        ...requestCartDelete,
        sho_cd: "",
        packno: 0,
      })

      // 確認メッセージ関係の処理をセット
      setMessageButtonOnClickKbn("cancel");
      setAlertMessage("修正をキャンセルします。よろしいですか？");

      setMessageBoxKbn(MessageBoxKbn.Confirmation);
      onOpen();
    } catch (e) {
      showBoundary(e);
    }
  };

  // カートを空にするボタン押下時処理
  const onClickCartDelete = () => {
    // console.log("onClickCartDelete");
    try {
      // カート削除リクエストをセット
      setRequestCartDelete({
        ...requestCartDelete,
        sho_cd: "",
        packno: 0,
      })

      // 確認メッセージ関係の処理をセット
      setMessageButtonOnClickKbn("allDelete");
      setAlertMessage("カートを空にします。よろしいですか？");

      setMessageBoxKbn(MessageBoxKbn.Confirmation);
      onOpen();
    } catch (e) {
      showBoundary(e);
    }
  };

  // 削除ボタン押下時処理
  const onClickDelete = async (sho_cd: string, packno: number, index: number) => {
    // console.log("onClickDelete");
    try {
      // カート削除リクエストをセット
      setRequestCartDelete({
        ...requestCartDelete,
        sho_cd: sho_cd,
        packno: packno
      })

      // 確認メッセージ関係の処理をセット
      setMessageButtonOnClickKbn("delete");
      setAlertMessage("カートから削除します。よろしいですか？");

      setMessageBoxKbn(MessageBoxKbn.Confirmation);
      onOpen();
    } catch (e) {
      showBoundary(e);
    }
  };

  // 修正時、修正ボタン押下時処理
  useEffect(() => {
    (async () => {
      if (orderList) {
        // console.log(orderList);
        try {
          if (orderList.hchu_data[0].rnki_ymd) {
            throw Error("notUpdate")
          } else {
            if (carts.length > 0) {
              // 納品日入力チェック
              nouDateValidation(update.nou_date)

              // 修正時、修正完了
              setMessageButtonOnClickKbn("order");
              setAlertMessage(
                `伝票番号：${storedData.storedDenNo}を修正します。\nよろしいですか？`
              );
            } else {
              // 修正時、カート内を空にした状態で修正するボタンを押下した場合、修正伝票を削除する
              setMessageButtonOnClickKbn("orderCancel");
              setAlertMessage(
                `伝票番号：${storedData.storedDenNo}を削除します。\nよろしいですか？`
              );
            }

            setMessageBoxKbn(MessageBoxKbn.Confirmation);
            onOpen();
          }
        } catch (e) {
          if (e instanceof Error) {
            switch (e.message) {
              case 'suryo':
                setAlertMessage(`入力可能な数量を入力してください。\n入力可能な数量は${MinRange + 1}以上、${MaxRange}以下になります。`);
                break;
              case 'decimalPlaces':
                setAlertMessage("小数点以下は入力できません。");
                break;
              case 'noneSuryo':
                setAlertMessage("数量が0又は未入力の明細があります。\n数量を入力してください。");
                break;
              case 'nouDate':
                setAlertMessage("納品可能日を入力してください。");
                break;
              case 'nouDateMax':
                setAlertMessage("納品日は1カ月以内の日付を入力してください。");
                break;
              case 'nouDateWeekday':
                setAlertMessage("納品日は日曜日以外の日付を入力してください。");
                break;
              case 'emptyCarts':
                setAlertMessage("カートが空です。");
                break;
              case 'notUpdate':
                //  カート情報削除
                await deleteCarts(requestCartDelete);

                // ローカルストレージの値を削除
                deleteStored();

                setOnClickWarningKbn(e.message)
                setAlertMessage("修正できない伝票です。");
                break;
              default:
                showBoundary(e);
                break;
            }
            setMessageBoxKbn(MessageBoxKbn.Warning);
            onOpen();
          } else {
            showBoundary(e);
          }
        }
      }
    })();
  }, [orderList])

  // 注文ボタン押下時処理
  const onClickOrder = () => {
    // console.log("onClickOrder");
    // console.log(carts)
    try {
      if (!login) return;

      // 数量入力チェック
      suryoValidation(selectCarts);
      suryoValidation(carts);

      if (location.state.shoriKbn === ShoriKbn.Add) {
        // 新規発注
        // console.log(create);
        if (carts.length > 0) {
          // 納品日入力チェック
          nouDateValidation(create.nou_date)

          // 新規注文確定
          setMessageButtonOnClickKbn("order");
          setAlertMessage("注文を確定します。よろしいですか？")
          setMessageBoxKbn(MessageBoxKbn.Confirmation);
          onOpen();
        } else {
          throw Error('emptyCarts')
        }

      } else if (location.state.shoriKbn === ShoriKbn.Update) {
        // console.log(update);
        // 発注情報取得
        getOrderList({ login_id: login.login_id, den_no: storedData.storedDenNo })

        // カート削除リクエストをセット
        setRequestCartDelete({
          ...requestCartDelete,
          sho_cd: "",
          packno: 0,
        })
      }
    } catch (e) {
      // console.error(e)
      if (e instanceof Error) {

        switch (e.message) {
          case 'suryo':
            setAlertMessage(`入力可能な数量を入力してください。\n入力可能な数量は${MinRange + 1}以上、${MaxRange}以下になります。`);
            break;
          case 'decimalPlaces':
            setAlertMessage("小数点以下は入力できません。");
            break;
          case 'noneSuryo':
            setAlertMessage("数量が0又は未入力の明細があります。\n数量を入力してください。");
            break;
          case 'nouDate':
            setAlertMessage("納品可能日を入力してください。");
            break;
          case 'nouDateMax':
            setAlertMessage("納品日は1カ月以内の日付を入力してください。");
            break;
          case 'nouDateWeekday':
            setAlertMessage("納品日は日曜日以外の日付を入力してください。");
            break;
          case 'emptyCarts':
            setAlertMessage("カートが空です。");
            break;
          case 'notUpdate':
            setAlertMessage("修正できない伝票です。");
            break;
          default:
            showBoundary(e);
            break;
        }
        setMessageBoxKbn(MessageBoxKbn.Warning);
        onOpen();
      } else {
        showBoundary(e);
      }
    }
  };

  // 数値変更処理(数量を変更する毎にカート情報を更新する)
  const onChangeNum = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    // console.log("onChangeNum");
    try {
      if (!login) return;

      // 前回の入力変更時の処理をキャンセル（APIが連続して呼び出されることを防ぐ）
      window.clearTimeout(timerRef.current);

      const suryo: number = '' ? 0 : Number(e.target.value)
      // console.log(suryo);
      const newCartIndex = carts.findIndex((data) => data.id === id);

      const newSelecrCarts = [...carts];
      newSelecrCarts[newCartIndex] = { ...newSelecrCarts[newCartIndex], suryo: suryo }
      // 入力した値を保持（入力可能範囲外の数値のまま注文されることを防ぐため）
      setSelectCarts(newSelecrCarts);

      // カート情報登録用のリクエストを作成
      const changeCarts = [...carts];
      changeCarts[newCartIndex] = { ...changeCarts[newCartIndex], suryo: suryo > 0 && suryo <= MaxRange ? suryo : 0 }

      const newCarts = changeCarts.filter((shohin) => shohin.suryo >= 0);
      // console.log(changeCarts);

      // カート情報更新リクエスト
      const requestCartData = newCarts.map((shohin) => ({
        login_id: login.login_id,    // ログイン時に取得しているデータ
        den_no: storedData.storedDenNo,               // 伝票番号ある場合はセットできるようにする(※修正時に発注入力画面に遷移した場合は伝票番号あり)
        biko: storedData.storedBiko,                // 記入がある場合はセットできるようにする
        sho_cd: shohin.sho_cd,
        sho_name: shohin.sho_name,
        naiyoryo: shohin.naiyoryo,
        naiyounit: shohin.naiyounit,
        packno: shohin.packno,
        packirisu: shohin.packirisu,
        packunit: shohin.packunit,
        packunitmei: shohin.packunitmei,
        tanka: shohin.tanka,
        suryo: shohin.suryo,
        zaiko_kbn: shohin.zaiko_kbn,
      }))

      const cartAddRequest: RequestAddCart = {
        cart_data: requestCartData
      }

      if (requestCartData.length === 0) {
        // カート内の商品の数量をすべて0にした場合、カート削除APIをコールする

        // カート情報削除APIをコール
        deleteCartData({
          login_id: login.login_id,    // ログインデータ
          sho_cd: "",
          packno: 0,
        });

      } else {
        const range = requestCartData.filter((shohin) => shohin.suryo > MaxRange)

        if (range.length === 0) {
          // カート情報更新APIをコール
          timerRef.current = window.setTimeout(() => {
            addCart(cartAddRequest);
          }, 500)
        }
      }

      // 数量変更で数量が0又は空白になった商品が画面上から削除されないようにする
      setCarts(changeCarts);

    } catch (e) {
      showBoundary(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (shohinTanka) {
        // 商品単価取得時にカートの単価情報を更新する

        await setCartTanka(shohinTanka, carts)

        getCartData({ login_id: carts[0].login_id })

      }
    })()

  }, [shohinTanka])

  // 納品日変更時処理
  const onChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.value);
    try {
      if (!login) return;

      // 前回の入力変更時の処理をキャンセル（APIが連続して呼び出されることを防ぐ）
      window.clearTimeout(timerRef.current);

      // 入力途中や空の状態でAPIを呼ぶとエラーになるため
      if (e.target.validity.badInput
        || e.target.value === ""
        || e.target.value.charAt(0) === "0") {
      } else {
        // 納品日をキーボードで入力中、0.5秒間次の入力がなければ処理を実施する
        // ※変更するたびに処理を実施しているとAPIがアクセス過多のエラーになるのでそれを防ぐ
        timerRef.current = window.setTimeout(() => {
          // 単価再取得
          getTanka({ login_id: login.login_id, nou_date: e.target.value })
        }, 500)
      }

      // 納品日の曜日を取得
      getWeekday(e.target.value);

      // ローカルストレージに保持している納品日の変更
      setStoredData({
        ...storedData,
        storedNouDate: e.target.value,
      })

      // 登録・更新のリクエストパラメータの納品日を変更
      if (location.state.shoriKbn === ShoriKbn.Add) {
        // 発注登録情報
        setCreate({
          ...create,
          nou_date: e.target.value,
        });
      } else if (location.state.shoriKbn === ShoriKbn.Update) {
        // 発注更新情報
        setUpdate({
          ...update,
          nou_date: e.target.value,
        })
      }
    } catch (e) {
      showBoundary(e);
    }
  }

  // 備考記入時処理
  const onChangeBiko = (e: ChangeEvent<HTMLTextAreaElement>) => {
    // console.log(e.target.value);

    try {
      setStoredData({
        ...storedData,
        storedBiko: e.target.value,
      })

      if (location.state.shoriKbn === ShoriKbn.Add) {
        // 発注登録情報に入力した備考内容をセット
        setCreate({
          ...create,
          biko: e.target.value,
        });
      } else if (location.state.shoriKbn === ShoriKbn.Update) {
        // 発注更新情報に入力した備考内容をセット
        setUpdate({
          ...update,
          biko: e.target.value,
        })
      }
    } catch (e) {
      showBoundary(e);
    }
  };

  return (
    <>
      <Stack m={{ base: 2, md: 4 }}>
        <Heading as="h1" size="md" textAlign="left">{
          location.state.shoriKbn === ShoriKbn.Refer ? "注文照会画面" : "注文確認画面"
        }</Heading>
        <Box display={{ lg: "flex" }}>
          <Text>下記の内容をご確認ください。</Text>
          <Spacer />
          <Flex justifyContent="flex-end">
            {/* 通常表示するボタン */}
            <PrimaryButton
              onClick={onClickCancel}
              bgGradient='linear(red.300, red.500)'
              color="white"
              // 修正時のみ表示する
              display={
                location.state.shoriKbn === ShoriKbn.Update ? { base: "none", sm: "block" } : "none"
              }
              icon={<Icon as={BsXLg} boxSize="16px" />}
              margin={2}
              paddingX={0}
              width={200}
            >修正キャンセル</PrimaryButton>
            <PrimaryButton
              onClick={onClickCartDelete}
              // bg="blue.400"
              bgGradient='linear(blue.300, blue.500)'
              color="white"
              display={location.state.shoriKbn === ShoriKbn.Refer ? "none" : { base: "none", sm: "block" }}
              icon={<Icon as={BsCartX} boxSize="16px" />}
              margin={2}
              width={200}
              paddingX={0}
            >カートを空にする</PrimaryButton>

            {/* レスポンシブ時に表示するボタン */}
            <PrimaryButton
              onClick={onClickCancel}
              bgGradient='linear(red.300, red.500)'
              color="white"
              // 修正時のみ表示する
              display={
                location.state.shoriKbn === ShoriKbn.Update ? { base: "flex", sm: "none" } : "none"
              }
              height={40}
              icon={<Icon as={BsXLg} boxSize="16px" />}
              margin={2}
              width={110}
              fontSize={14}
            >修正<br />キャンセル</PrimaryButton>
            <PrimaryButton
              onClick={onClickCartDelete}
              // bg="blue.400"
              bgGradient='linear(blue.300, blue.500)'
              color="white"
              display={location.state.shoriKbn === ShoriKbn.Refer ? "none" : { base: "flex", sm: "none" }}
              height={40}
              icon={<Icon as={BsCartX} boxSize="16px" />}
              margin={2}
              width={110}
              fontSize={14}
            >カートを<br />空にする</PrimaryButton>
          </Flex>
        </Box>

        {/* （テーブル）注文商品一覧 */}
        <TableContainer overflowX={{ base: "scroll", md: "auto" }} w="100%" whiteSpace="normal">
          <Table size="sm">
            <Thead bg="#006DA7">
              <Tr>
                <Th colSpan={3} w={{ base: "auto", md: "45%" }}>商品名</Th>
                <Th maxW="90px" minW="68px" rowSpan={2}>数量</Th>
                <Th minW="80px" rowSpan={2}>単位</Th>
                <Th minW="90px" rowSpan={2}>金額</Th>
                <Th minW="118px" rowSpan={2}>商品コード</Th>
                <Th minW="95px" rowSpan={2}></Th>
              </Tr>
              <Tr>
                <Th minW="80px" textAlign="center" px={2} w={{ base: "auto", md: "15%" }}>内容量</Th>
                <Th minW="75px" textAlign="center" px={2} w={{ base: "auto", md: "15%" }}>入数</Th>
                <Th minW="90px" textAlign="center" w={{ base: "auto", md: "15%" }}>単価</Th>
              </Tr>
            </Thead>
            <Tbody>
              {carts?.map((cart, index) => (
                <Fragment key={cart.id}>
                  <Tr bg={index % 2 === 0 ? "white" : "#D4E5EF"}>
                    <Td colSpan={3} textAlign="left">{cart.sho_name}</Td>
                    <Td maxW="90px" p={0} rowSpan={2}>

                      <NumberInput
                        clampValueOnBlur={false}
                        defaultValue={cart.suryo === 0 ? "" : cart.suryo}
                        isReadOnly={location.state.shoriKbn === ShoriKbn.Delete || location.state.shoriKbn === ShoriKbn.Refer ? true : false}
                        m={0}
                        max={MaxRange}
                        min={MinRange}
                        size="sm"
                      >
                        <NumberInputField
                          onChange={(e) => onChangeNum(e, cart.id)}
                          onKeyDown={(e) => {
                            onkeydownNum(e);
                          }}
                          fontSize="18px"
                          borderColor="gray.500" h={{ base: "85px", md: "auto" }} minH="68px" pr={3} textAlign="right"
                        />
                      </NumberInput>
                    </Td>
                    <Td rowSpan={2}>
                      {cart.packunitmei}
                    </Td>
                    <Td rowSpan={2} textAlign="right">
                      {
                        (cart.tanka * cart.suryo % 1 === 0)
                          ? (cart.tanka * cart.suryo).toLocaleString()
                          : (Math.round(cart.tanka * cart.suryo)).toLocaleString()
                      }円
                    </Td>
                    <Td rowSpan={2}>{cart.sho_cd}</Td>
                    <Td rowSpan={2}>
                      <SecondaryButton
                        onClick={() => onClickDelete(cart.sho_cd, cart.packno, index)}
                        bgGradient='linear(red.300, red.500)'
                        color="white"
                        isDisabled={location.state.shoriKbn === ShoriKbn.Delete || location.state.shoriKbn === ShoriKbn.Refer ? true : false}
                        tabIndex={-1}
                        width={80}
                      >削除</SecondaryButton>
                    </Td>
                  </Tr>
                  <Tr bg={index % 2 === 0 ? "white" : "#D4E5EF"}>
                    <Td textAlign="right">
                      {(cart.naiyoryo === 0 || cart.naiyoryo.toString() === "0.000") ? "" : `${cart.naiyoryo.toLocaleString(undefined, { minimumFractionDigits: 3 })}${cart.naiyounit}`}
                    </Td>
                    <Td textAlign="right">{cart.packirisu}</Td>
                    <Td textAlign="right">
                      {
                        cart.tanka % 1 === 0
                          ? Math.floor(cart.tanka).toLocaleString()
                          : cart.tanka.toLocaleString()
                      }円
                    </Td>
                  </Tr>
                </Fragment>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex justifyContent="right">
          <Text mr={{ base: "16px", sm: "32px" }}>
            商品件数 {carts?.length}件
          </Text>
          <Text>
            合計金額（税別） {(carts?.reduce((acc: number, cart) => acc + Math.round(cart.tanka * cart.suryo), 0) ?? 0).toLocaleString()}円
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Text m={2} w="65px">納品日</Text>
          <DateInput
            onChange={onChangeDate}
            isReadOnly={location.state.shoriKbn === ShoriKbn.Delete || location.state.shoriKbn === ShoriKbn.Refer ? true : false}
            // defaultValue={storedData.storedNouDate}
            value={storedData.storedNouDate}
            min={login ? login.login_data.nou_kano_date : ""}    //　ログインデータの納品可能日以前は入力不可
          />
          <Text m={2}>({(weekday === "" || weekday === "Invalid Date") ? "納品日の曜日を表示" : weekday.charAt(0)})</Text>
        </Flex>
        <Textarea
          // defaultValue={storedData.storedBiko}
          value={storedData.storedBiko}
          isReadOnly={location.state.shoriKbn === ShoriKbn.Delete || location.state.shoriKbn === ShoriKbn.Refer ? true : false}
          maxLength={1000}
          onChange={onChangeBiko}
          placeholder='お問い合わせ等ございましたら、こちらに入力してください。'
        />
        <Flex justifyContent="center">
          <PrimaryButton
            onClick={onClickOrder}
            bgGradient="linear(blue.400, blue.600)"
            color="white"
            display={location.state.shoriKbn === ShoriKbn.Refer ? "none" : ""}
            icon={<Icon as={BsCartCheck} boxSize="16px" />}
            paddingX={0}
            width={200}
          >{location.state.shoriKbn === ShoriKbn.Add ? "注文する"
            : location.state.shoriKbn === ShoriKbn.Update ? "修正する"
              : location.state.shoriKbn === ShoriKbn.Delete ? "削除する"
                : ""
            }</PrimaryButton>
        </Flex>
      </Stack>

      <MessageBox
        isOpen={isOpen}
        onClose={onClose}
        onClickConfirmation={() => onClickMessageButton(messageButtonOnClickKbn)}
        onClickInfomation={() => onClickOk()}
        onClickWarning={() => onClickWarning(onClickWarningKbn)}
        cancelRef={cancelRef}
        messageBoxKbn={messageBoxKbn}
        alertMessage={alertMessage}
      />

      <PageTopScrollButton showButton={showButton} />
    </>
  )
});

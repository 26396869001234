import { useBoolean } from '@chakra-ui/react';

export const usePageTopScroll = () => {

  const [showButton, setShowButton] = useBoolean();

  // Scrollを検知しボタン表示のフラグを切り替え
  const watchScroll = () => {
    const basePosition = 200;
    const scrollPosition = window.scrollY;
    if (basePosition <= scrollPosition) {
      setShowButton.on();
    } else {
      setShowButton.off();
    }
  };

  return { watchScroll, showButton };
};

import { useErrorBoundary } from "react-error-boundary"

/* 動作内容
  1. fetch("/")の処理でサーバー上の画面情報（index.html）を取得。
      ※index.htmlの内容は全画面で共通しているのでログイン画面のデータを取得し判断する。
  2. ローカルストレージが空の場合（初回ログイン時）はサーバーから取得した内容をローカルストレージに保存する。
      ※初回以降は3の処理を実施。
  3. ローカルストレージとサーバーから取得した内容と差異がある場合、サーバーから取得した内容をローカルストレージに保存し画面をリロードする。
  4. 処理が呼び出される度に1，3の処理を行う。
*/

// 画面を常に最新状態にするカスタムフック
export const useGetIndexData = () => {
  const { showBoundary } = useErrorBoundary()

  const getIndexData = () => {
    try {
      // サーバー上の画面情報（index.html）を取得
      fetch("/")
        .then((response) => response.text())
        .then((serverData) => {
          // 表示している内容（ローカルストレージに保持）とサーバー上の内容に差異がないか確認
          if (!localStorage.getItem("indexData")) {
            // 現在の画面情報をローカルストレージに保存
            // （初回アクセス時はリロードしない）
            localStorage.setItem("indexData", serverData)

          } else if (serverData !== localStorage.getItem("indexData")) {

            // 現在の画面情報をローカルストレージに保存
            localStorage.setItem("indexData", serverData)

            // 画面情報が変更された場合、画面をリロードする
            window.location.reload();
          }
        })
        .catch((e) => {
          throw Error(e)
        })
    } catch (e) {
      showBoundary(e)
    }
  }

  return { getIndexData }
}

import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        // backgroundColor: "gray.50", //全体で背景の色変えるなら変更
        color: "gray.700",

      },
    },
  },
  components: {
    // チェックボックスコンポーネントのカスタマイズ
    Checkbox: {
      baseStyle: {
        control: {
          borderColor: "RGBA(0, 0, 0, 0.48)",
        }
      }
    },
    // Inputコンポーネントのカスタマイズ
    Input: {
      baseStyle: {
        field: {
          fontSize: "18px",
          // w: "190px"
        },
      },
      defaultProps: {
        size: "md",
      },
    },
    // Selectコンポーネントのカスタマイズ
    Select: {
      baseStyle: {
        field: {
          fontSize: "18px",
        },
      },
      defaultProps: {
        size: "md",
      },
    },
    // Textコンポーネントのカスタマイズ
    Text: {
      baseStyle: {
        fontSize: "18px",
      },
      // ラベルの場合は16px
      sizes: {
        xs: {
          fontSize: "16px",
        },
      },
    },
    // Textareaコンポーネントのカスタマイズ
    Textarea: {
      baseStyle: {
        fontSize: "18px",
      },
    },
    //Headerコンポーネントのカスタマイズ
    Heading: {
      baseStyle: {
        mt: "5px"
      },
      sizes: {
        md: {
          fontSize: "24px",
        },
      },
    },
    // Tableコンポーネントのカスタマイズ
    Table: {
      baseStyle: {
        th: {
          height: "35px",
          border: "solid 2px",
          textAlign: "center",
        },
        td: {
          border: "solid 2px",
          textAlign: "center",
        },
      },
      variants: {
        simple: {
          th: {
            borderColor: "#DCDDDD",
            fontSize: "16px",
            color: "white",
          },
          td: {
            borderColor: "#DCDDDD",
            px: "2",
            fontSize: "18px"
          },
        },
      },
    },
  }
});

export default theme;

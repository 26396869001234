import { useEffect, useState } from 'react'

// ローカルストレージの値を管理するカスタムフック

type StoredData = {
  storedDenNo: number;
  storedNouDate: string;
  storedBiko: string;
}

export const useLocalStorage = (initialValue: StoredData) => {
  const [storedData, setStoredData] = useState<StoredData>(() => {
    const items = localStorage.getItem('storedData');
    return items ? JSON.parse(items) : initialValue;
  });

  useEffect(() => {
    localStorage.setItem('storedData', JSON.stringify(storedData));
  }, [storedData])

  return { storedData, setStoredData }
}

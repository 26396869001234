import { useCallback } from 'react'

// 共通関数
import { ApiClient } from '../utility/Utility';
import { RequestUpdateStandardTokItem, RequestUpdateStandardRankItem } from '../types/StandardItemDataType';

// 定番品マスタを更新するカスタムフック
export const useUpdateStandardItem = () => {

    // 定番品(得意先単位)更新API
    const updateStandardTokItem = useCallback(async (request: RequestUpdateStandardTokItem) => {

        await ApiClient.put('api/teban/update/', request)
            .then((response) => {
                //console.log(response.status);
            })
            .catch((error) => {
                //console.log(error);
                throw error
            });
    }, []);

    // 定番品(ランク単位)更新API
    const updateStandardRankItem = useCallback(async (request: RequestUpdateStandardRankItem) => {

        await ApiClient.put('api/tebanrank/update/', request)
            .then((response) => {
                // console.log(response.status);
            })
            .catch((error) => {
                //console.log(error);
                throw error
            });
    }, []);

    return { updateStandardTokItem, updateStandardRankItem };
};

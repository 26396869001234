import React, { FC, ReactElement, ReactNode, memo } from 'react'

import { Button, ResponsiveValue } from "@chakra-ui/react";

type Props = {
  children: ReactNode;
  onClick: () => void;
  bg?: string;
  bgGradient?: string;
  color: string;
  display?: ResponsiveValue<string>;
  fontSize?: number;
  icon?: ReactElement;
  isDisabled?: boolean;
  margin?: number;
  paddingX?: number;
  tabIndex?: number;
  height?: number;
  width: number;
}

// 主要ボタン　初期値が入っていない値は使用時に設定
export const PrimaryButton: FC<Props> = memo((props) => {
  const {
    children,
    onClick,
    bg = "",
    bgGradient = "",
    color,
    display = "",
    fontSize = 16,
    height = 35,
    icon = undefined,
    isDisabled = false,
    margin = 0,
    paddingX = 3,
    tabIndex = 0,
    width,
  } = props;

  return (
    <Button
      onClick={onClick}
      bg={bg}
      bgGradient={bgGradient}
      color={color}
      display={display}
      fontSize={`${fontSize}px`}
      fontWeight="none"
      h={`${height}px`}
      _hover={{ opacity: 0.8 }}
      isDisabled={isDisabled}
      leftIcon={icon}
      m={margin}
      px={paddingX}
      tabIndex={tabIndex}
      w={`${width}px`}
    >
      {children}
    </Button>
  );
})

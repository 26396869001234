/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, KeyboardEvent, memo, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useErrorBoundary } from "react-error-boundary";

import axios from "axios";

import Bugsnag from "@bugsnag/js";

import {
  Box,
  Flex,
  Input,
  InputGroup,
  Stack,
  Text,
  Button,
  Icon,
  InputRightElement,
  Heading,
  Card,
  CardHeader,
  CardBody,
  StackDivider,
  Link,
  useDisclosure,
} from "@chakra-ui/react";

import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";

import { ApiClient, formattedDate } from "../../utility/Utility";
import { LoginData, LoginResponseData, StoredLogin } from "../../types/LoginDataType";
import { useAuthContext } from "../../providers/AuthProvider";
import { SecondaryButton } from "../atoms/buttons/SecondaryButton";
import { useDeleteCarts } from "../../hooks/useDeleteCarts";
import logo from "../../resources/logo.png";
import { useGetIndexData } from "../../hooks/useGetIndexData";
import { useGetInfo } from "../../hooks/useGetInfo";
import { ModalInfo } from "../atoms/ModalInfo";
import { InfoListData } from "../../types/InfoType";

type RequestLogin = {
  login_id: string;
  password: string;
}

export const Login = memo(() => {

  const { showBoundary } = useErrorBoundary();
  const { getIndexData } = useGetIndexData();

  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const { isAuth, signin } = useAuthContext();

  const { deleteCartData } = useDeleteCarts();
  const { getInfoList, infoList } = useGetInfo();

  const [show, setShow] = useState(false);
  const onClickShow = () => setShow(!show)

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectInfo, setSelectInfo] = useState<InfoListData[] | null>(null);
  const [infoOpenFlg, setInfoOpenFlg] = useState(false);

  const [alertMessage, setAlertMessage] = useState("")

  // エラーメッセージ
  const errorMessage = (errorType: string, e: any) => {
    switch (errorType) {
      case 'emptyLoginId':
        setAlertMessage("ログインIDを入力してください。");
        break;
      case 'emptyPassword':
        setAlertMessage("パスワードを入力してください。");
        break;
      case 'validLoginId':
        setAlertMessage("ログインIDに全角は入力できません。");
        break;
      case 'validPassword':
        setAlertMessage("パスワードに全角は入力できません。");
        break;
      default:
        setAlertMessage("エラーが発生しました。")
        // 予期せぬエラーはBugsnagでキャッチできるようにしておく
        Bugsnag.notify(e)
        break;
    }
  }

  useEffect(() => {
    // お知らせ情報を取得
    getInfoList();
  }, [])

  // 未認証時ローカルストレージを削除する
  useEffect(() => {
    if (isAuth) {

    } else {
      localStorage.removeItem('login');
      localStorage.removeItem('storedData')
      localStorage.removeItem('state')
    }
  }, [isAuth])

  // ログイン情報取得処理
  const getLoginUser = async (request: RequestLogin) => {

    await ApiClient.post<LoginResponseData>('api/accounts/login/', request)
      .then((response) => {
        // ログインデータが取得できた場合の処理
        // console.log(response)

        // ログイン時にカートを空にする（カート削除APIを呼び出す）
        deleteCartData({
          login_id: loginId,
          sho_cd: "",
          packno: 0,
        })

        // ローカルストレージを空にする
        localStorage.removeItem('login');
        localStorage.removeItem('storedData')
        localStorage.removeItem('state')

        const loginData: LoginData = {
          admin_kbn: response.data.admin_kbn,
          teban_reg: response.data.teban_reg,
          nou_kano_date: response.data.nou_kano_date,
          tok_mei: response.data.tok_mei,
        }

        const login: StoredLogin = {
          login_id: loginId,
          login_data: loginData
        }

        // ローカルストレージにトークン以外の値を保存
        localStorage.setItem('login', JSON.stringify(login));

        // トークンの値をCockieに保存

      })
      .catch((error) => {
        // console.error(error)
        Bugsnag.notify(error);

        if (error.response) {
          if (error.response.status < 500) {
            const message: Array<string> = Object.values(error.response.data)
            const errorMessage: string = message[0]

            throw new axios.AxiosError(errorMessage)
          } else {
            throw new axios.AxiosError("エラーが発生しました。")
          }
        } else {
          throw new axios.AxiosError(error.message)
        }
      })
  }

  // 入力チェック
  const validText = (value: string) => {
    const regex = /[^a-zA-Z0-9!-/:-@[-`{-~]/
    // 半角英数字記号以外の場合trueを返すtest関数
    const result = regex.test(value)

    return result
  }

  const onChangeLoginId = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setLoginId(e.target.value);

      if (validText(e.target.value)) {
        throw Error('validLoginId')

      } else {
        setAlertMessage("");
      }

    } catch (e) {
      if (e instanceof Error) {
        errorMessage(e.message, e)
      } else {
        showBoundary(e);
      }
    }
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {

    try {
      setPassword(e.target.value);

      if (validText(e.target.value)) {
        throw Error('validPassword')

      } else {
        setAlertMessage("");
      }

    } catch (e) {
      if (e instanceof Error) {
        errorMessage(e.message, e)
      } else {
        // その他の予期せぬエラー
        showBoundary(e);
      }
    }
  };

  // ログインボタン押下時処理
  const onClickLogin = async () => {
    // console.log(password)
    try {
      if (loginId === "") {
        throw Error('emptyLoginId')

      } else if (password === "") {
        throw Error('emptyPassword')

      } else if (validText(loginId)) {
        throw Error('validLoginId')

      } else if (validText(password)) {
        throw Error('validPassword')

      } else {
        // ログインデータ取得API
        await getLoginUser({
          login_id: loginId,
          password: password
        });

        // サインイン処理
        signin();

        // ローカルストレージの値を取得（API呼び出し後セットされる）
        const item = localStorage.getItem("login");

        if (item) {
          const login: StoredLogin = JSON.parse(item)

          // ログインデータの管理者区分が1の場合、発注明細一覧に遷移
          if (login.login_data.admin_kbn === 1) {
            getIndexData();
            navigate("/OrderDetailHistory", { state: { login: true } })
          } else {
            getIndexData();
            // 管理者でない場合は発注入力画面に遷移
            navigate("/Order", { state: { login: true } });
          }
        }
      }
    } catch (e) {
      // console.log(e)
      if (axios.isAxiosError(e)) {
        setAlertMessage(e.message)
        return;
      } else {
        if (e instanceof Error) {
          errorMessage(e.message, e)

        } else {
          // その他の予期せぬエラー
          showBoundary(e);
        }
      }
    }
  };

  // パスワード欄でEnterキー押下時にログインボタンを押下する処理
  const onkeydownLoginEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    try {
      if (e.key !== "Enter") return;

      e.preventDefault();

      onClickLogin()
    } catch (e) {
      showBoundary(e);
    }
  };

  // お知らせ情報画面を表示する
  useEffect(() => {
    if (infoOpenFlg && selectInfo) {
      // console.log(selectInfo)

      onOpen();
      setInfoOpenFlg(false)
    }
  }, [infoOpenFlg])

  const onClickInfo = (msg_id: number) => {
    try {
      if (!infoList) return;

      const clickInfo: InfoListData[] = infoList.info_list_data.filter((item) => item.msg_id === msg_id);
      setInfoOpenFlg(true)
      setSelectInfo(clickInfo)
    } catch (e) {
      showBoundary(e);
    }
  }

  return (
    <>
      <Flex align="center" flexDir="column" justify="center" h="100vh">
        <Box bgGradient='linear(#6F94CD, #006AB7, blue.700)' w="sm" p={4} borderRadius="md" shadow="md">
          <Flex justify="center" pt={4} pb={4}>
            <img src={logo} alt="" />
          </Flex>
          <Box px={9}>
            <Text size="xs" color="red" bg="white" pl={1}>{alertMessage}</Text>
          </Box>
          <Stack spacing={6} py={4} px={10}>

            <Input
              onChange={onChangeLoginId}
              placeholder="ログインID"
              bg="white"
              required
            />
            <InputGroup>
              <Input
                onChange={onChangePassword}
                onKeyDown={onkeydownLoginEnter}
                placeholder="パスワード"
                bg="white"
                // 表示、非表示ボタンを表示する場合は下記プロパティを使用する
                // pr="40px"
                required
                type={show ? "text" : "password"}
              />
              {/* 表示、非表示ボタンを表示する場合は下記要素を使用する */}
              {/* <InputRightElement>
              <Button
                onClick={onClickShow}
                bg="gray.50"
                _hover={{ opacity: "none" }}
                size="sm"
                tabIndex={-1}
                mr={1}
              >
                <Icon as={show ? VscEyeClosed : VscEye} boxSize={6} />
              </Button>
            </InputRightElement> */}
            </InputGroup>

            <SecondaryButton
              onClick={onClickLogin}
              bgGradient="linear(gray.100, gray.300)"
              color="#333333"
              width={272}
            >
              ログイン
            </SecondaryButton>

          </Stack>
        </Box>

        {/* お知らせ情報一覧 */}
        <Card display={infoList && infoList.info_list_data.length > 0 ? "" : "none"} maxH="130px" w={{ base: "sm", md: "70vh" }} overflowY="scroll" mt={10}>
          <CardHeader p={4} pb={0}>
            <Heading size="sm">お知らせ</Heading>
          </CardHeader>
          <CardBody p={4}>
            <Stack divider={<StackDivider />}>
              {infoList?.info_list_data.map((item) => (
                <Flex key={item.msg_id} borderBottom="gray.500">
                  <Text size="xs" mr={4} whiteSpace="nowrap">{formattedDate(item.kbgnymd)}</Text>
                  <Link
                    size="16px"
                    color='blue.500'
                    textDecoration="underline"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    onClick={() => onClickInfo(item.msg_id)}
                  >{item.msg_title}</Link>
                </Flex>
              ))}
            </Stack>
          </CardBody>
        </Card>
      </Flex >

      {/* お知らせ情報画面 */}
      <ModalInfo isOpen={isOpen} onClose={onClose} data={selectInfo} />

    </>
  );
});
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FocusEvent, ChangeEvent, useEffect, useState } from 'react'
import { useErrorBoundary } from 'react-error-boundary';
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Link,
} from "@chakra-ui/react";

import { BsSearch } from "react-icons/bs";

import { useNavigate } from 'react-router-dom';

// 共通関数
import { MessageBoxKbn, ShoriKbn } from '../../utility/Utility';

//コンポーネント
import { PrimaryButton } from "../atoms/buttons/PrimaryButton";
import { SecondaryButton } from '../atoms/buttons/SecondaryButton';
import { PageTopScrollButton } from '../atoms/buttons/PageTopScrollButton';
import { DateInput } from "../atoms/Input/DateInput";
import { MessageBox } from '../atoms/MessageBox';
import { OrderListRequest } from '../../types/OrderType';
import { RequestAddCart } from '../../types/CartDataType';

// カスタムHook
import { OrderHistoryRequest, useOrderHistory } from "../../hooks/useOrderHistory"
import { usePageTopScroll } from '../../hooks/usePageTopScroll';
import { useAddCarts } from "../../hooks/useAddCarts";
import { useGetTokuisakiList } from "../../hooks/useGetTokuisakiList";
import { useGetOrderList } from "../../hooks/useGetOrderList";
import { useDeleteOrder } from "../../hooks/useDeleteOrder";
import { useAuthContext } from '../../providers/AuthProvider';
import { useGetIndexData } from '../../hooks/useGetIndexData';


export const OrderHistory = () => {

  const { showBoundary } = useErrorBoundary();
  const { getIndexData } = useGetIndexData();

  const navigate = useNavigate();

  // メッセージ表示
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [messageBoxKbn, setMessageBoxKbn] = useState(0);

  const [shoriKbnFlg, setShoriKbnFlg] = useState(0)

  // メッセージボックスに表示するメッセージ
  const [alertMessage, setAlertMessage] = useState("");

  // 得意先取得API
  const { getTokuisaki, tokuisakiData } = useGetTokuisakiList();

  // ページトップへ移動する処理
  const { watchScroll, showButton } = usePageTopScroll();

  // 発注日（開始）初期値取得
  const today = new Date();   // 当日日付取得
  const hchuDate = today;
  hchuDate.setMonth(today.getMonth() - 1);    // 前月同日の日付データを取得
  // 取得した日付のスタイルを変更（yyyy-MM-dd）
  const hachuDateFr =
    `${hchuDate.getFullYear()}-${(hchuDate.getMonth() + 1).toString().padStart(2, '0')}-${hchuDate.getDate().toString().padStart(2, '0')}`

  // 検索項目入力チェック用
  // 発注日（開始）
  const [isBadInputHachuDateFr, setIsBadInputHachuDateFr] = useState(false);
  // 発注日（終了）
  const [isBadInputHachuDateTo, setIsBadInputHachuDateTo] = useState(false);
  // 納品日（開始）
  const [isBadInputNouDateFr, setIsBadInputNouDateFr] = useState(false);
  // 納品日（終了）
  const [isBadInputNouDateTo, setIsBadInputNouDateTo] = useState(false);

  // スクロールイベントを拾う処理
  useEffect(() => {
    window.addEventListener("scroll", watchScroll);
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);

  // ローカルストレージ関連
  // ログインデータ
  const { login } = useAuthContext();

  const [hachuData, setHachuData] = useState({
    denNo: 0,
    nouDate: "",
    biko: "",
  })

  // 注文確認画面遷移時の処理区分
  const [shoriKbn, setShoriKbn] = useState(0);

  // カート情報更新API
  const { addCart } = useAddCarts();

  // 発注情報取得API
  const { orderList, getOrderList } = useGetOrderList()

  // 発注情報削除API
  const { orderDelete } = useDeleteOrder();
  const [deleteDenNo, setDeleteDenNo] = useState(0);

  // 発注履歴情報取得リクエストパラメータ
  const [requestGetOrderHistory, setRequestGetOrderHistory] = useState<OrderHistoryRequest>({
    login_id: '',
    hchu_date_from: '',
    hchu_date_to: '',
    nou_date_from: '',
    nou_date_to: '',
    tok_cd_from: 0,
    tok_cd_to: 999999,
    tan_tok_flg: 0
  })

  // 発注日入力チェック
  const hachuDateValidation = (hachuDateFr: string, hachuDateTo: string) => {
    const validDateFr = new Date(hachuDateFr)
    const validDateTo = new Date(hachuDateTo)

    // 入力チェック
    if (isBadInputHachuDateFr) {
      throw Error("BadInputHachuDateFr")
    } else if (isBadInputHachuDateTo) {
      throw Error("BadInputHachuDateTo")
    }
    // 大小チェック
    if (validDateFr > validDateTo) {
      throw Error("hachuDate")
    } else {
      return;
    }
  }

  // 納品日入力チェック
  const nouDateValidation = (nouDateFr: string, nouDateTo: string) => {
    const validDateFr = new Date(nouDateFr)
    const validDateTo = new Date(nouDateTo)

    // 入力チェック
    if (isBadInputNouDateFr) {
      throw Error("BadInputNouDateFr")
    } else if (isBadInputNouDateTo) {
      throw Error("BadInputNouDateTo")
    }
    // 大小チェック
    if (validDateFr > validDateTo) {
      throw Error("nouDate")
    } else {
      return;
    }
  }

  const onClickSearch = () => {
    try {
      // 発注日入力チェック
      hachuDateValidation(requestGetOrderHistory.hchu_date_from, requestGetOrderHistory.hchu_date_to);
      // 納品日入力チェック
      nouDateValidation(requestGetOrderHistory.nou_date_from, requestGetOrderHistory.nou_date_to);

      // 発注履歴一覧再取得
      getOrderHistoryData(requestGetOrderHistory)
    } catch (e) {
      if (e instanceof Error) {
        switch (e.message) {
          case 'hachuDate':
            setAlertMessage("検索条件の発注日（開始）は発注日（終了）より過去の日付を入力してください。");
            break;
          case 'nouDate':
            setAlertMessage("検索条件の納品日（開始）は納品日（終了）より過去の日付を入力してください。");
            break;
          case 'BadInputHachuDateFr':
            setAlertMessage("検索条件の発注日（開始）が有効な日付ではありません。");
            break;
          case 'BadInputHachuDateTo':
            setAlertMessage("検索条件の発注日（終了）が有効な日付ではありません。");
            break;
          case 'BadInputNouDateFr':
            setAlertMessage("検索条件の納品日（開始）が有効な日付ではありません。");
            break;
          case 'BadInputNouDateTo':
            setAlertMessage("検索条件の納品日（終了）が有効な日付ではありません。");
            break;
          default:
            showBoundary(e);
            break;
        }
        setMessageBoxKbn(MessageBoxKbn.Warning);
        onOpen();
      } else {
        showBoundary(e);
      }
    }
  };

  const onClickConfirmationOK = async () => {
    try {
      if (!login) return;

      // 発注情報削除API
      await orderDelete({
        login_id: login.login_id,
        den_no: deleteDenNo
      });

      // 一覧再読み込み
      await getOrderHistoryData(requestGetOrderHistory);

      // 削除伝票番号をクリア
      setDeleteDenNo(0);

      // 完了メッセージ表示
      setMessageBoxKbn(MessageBoxKbn.Infomation);
      setAlertMessage("伝票を削除しました。");
      onOpen()
    } catch (e) {
      showBoundary(e);
    }
  }

  // 発注履歴情報を取得するカスタムフック
  const { getOrderHistoryData, orderHistory } = useOrderHistory();

  // 初回レンダリング時
  useEffect(() => {
    if (!login) return;

    // 最新状態保持用
    getIndexData();

    setRequestGetOrderHistory({
      login_id: login.login_id,
      hchu_date_from: hachuDateFr, //前月同日
      hchu_date_to: "",
      nou_date_from: "",
      nou_date_to: "",
      tok_cd_from: 0,
      tok_cd_to: 0,
      tan_tok_flg: login.login_data.admin_kbn === 1 ? 1 : 0,
    });

    getOrderHistoryData({
      login_id: login.login_id,
      hchu_date_from: hachuDateFr,
      hchu_date_to: "",
      nou_date_from: "",
      nou_date_to: "",
      tok_cd_from: 0,
      tok_cd_to: 0,
      tan_tok_flg: login.login_data.admin_kbn === 1 ? 1 : 0,
    });

    // ログインデータが管理者の場合実施
    if (login.login_data.admin_kbn === 1) {
      getTokuisaki({ login_id: login.login_id });
    }
  }, [])

  // 注文確認画面遷移処理
  useEffect(() => {
    if (shoriKbn === ShoriKbn.Update || shoriKbn === ShoriKbn.Refer) {
      navigate("/OrderConfirmation", {
        state: {
          shoriKbn: shoriKbn,
          hachuData: hachuData
        }
      });

      // 引数で渡した発注情報を初期化
      setHachuData({
        denNo: 0,
        nouDate: "",
        biko: "",
      })

      setShoriKbn(0)
      setShoriKbnFlg(0)
    }
  }, [shoriKbn])

  useEffect(() => {
    (async () => {
      try {
        if (!login) return;

        if (orderList) {
          // カート内容の作成(カート情報更新時に渡すリクエストパラメータ)
          const newCartData = orderList?.hchu_data.map((shohin) => ({
            login_id: login.login_id,
            den_no: shohin.den_no,
            biko: shohin.biko,
            sho_cd: shohin.sho_cd,
            sho_name: shohin.sho_name,
            naiyoryo: shohin.naiyoryo,
            naiyounit: shohin.naiyounit,
            packno: shohin.packno,
            packirisu: shohin.packirisu,
            packunit: shohin.packunit,
            packunitmei: shohin.packunitmei,
            tanka: shohin.tanka,
            suryo: shohin.suryo,
            zaiko_kbn: shohin.zaiko_kbn,
          }));

          const cartRequest: RequestAddCart = {
            cart_data: newCartData
          }

          await addCart(cartRequest);

          // ローカルストレージをクリア
          localStorage.removeItem('storedData');

          setHachuData({
            denNo: newCartData[0].den_no,
            nouDate: orderList.hchu_data[0].nou_date,  //登録されている納品日をセット
            biko: newCartData[0].biko,
          })

          // ページ遷移
          setShoriKbn(shoriKbnFlg);
        }
      } catch (e) {
        showBoundary(e);
      }
    })()
  }, [orderList])

  // 修正ボタン押下時処理
  const onClickUpdate = async (denNo: number) => {
    try {
      if (!login) return;

      const newOrderRequest: OrderListRequest = {
        login_id: login.login_id,
        den_no: denNo,
      }

      await getOrderList(newOrderRequest);

      setShoriKbnFlg(ShoriKbn.Update)

    } catch (e) {
      showBoundary(e);
    }
  };

  // 削除ボタン押下時処理
  const onClickDelete = (denNo: number) => {
    try {
      setDeleteDenNo(denNo)
      // 確認メッセージ表示
      setMessageBoxKbn(MessageBoxKbn.Confirmation)
      setAlertMessage(`伝票番号：${denNo}を削除します。
                     よろしいですか？`)
      onOpen();
    } catch (e) {
      showBoundary(e);
    }
  };

  // 件数リンク押下時処理
  const onClickRefer = async (denNo: number) => {
    try {
      if (!login) return;

      const newOrderRequest: OrderListRequest = {
        login_id: login.login_id,
        den_no: denNo,
      }

      await getOrderList(newOrderRequest);

      setShoriKbnFlg(ShoriKbn.Refer)

    } catch (e) {
      showBoundary(e);
    }
  };

  // 発注日（開始）入力処理
  const onChangeHchuDateFr = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setRequestGetOrderHistory({
        ...requestGetOrderHistory,
        hchu_date_from: e.target.value,
      })
    } catch (e) {
      showBoundary(e);
    }
  }

  // 発注日（開始）フォーカスが離れるときの処理
  // ※Changeイベントでの入力チェックでは未入力時と入力途中の区別ができないため
  const onBlurHchuDateFr = (e: FocusEvent<HTMLInputElement>) => {
    try {
      setIsBadInputHachuDateFr(e.target.validity.badInput);

    } catch (e) {
      showBoundary(e);
    }
  }

  // 発注日（終了）入力処理
  const onChangeHchuDateTo = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setRequestGetOrderHistory({
        ...requestGetOrderHistory,
        hchu_date_to: e.target.value,
      })
    } catch (e) {
      showBoundary(e);
    }
  }

  // 発注日（終了）フォーカスが離れるときの処理
  const onBlurHchuDateTo = (e: FocusEvent<HTMLInputElement>) => {
    try {
      setIsBadInputHachuDateTo(e.target.validity.badInput);

    } catch (e) {
      showBoundary(e);
    }
  }

  // // 納品日（開始）入力処理
  const onChangeNouDateFr = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setRequestGetOrderHistory({
        ...requestGetOrderHistory,
        nou_date_from: e.target.value,
      })
    } catch (e) {
      showBoundary(e);
    }
  }

  // 納品日（開始）フォーカスが離れるときの処理
  const onBlurNouDateFr = (e: FocusEvent<HTMLInputElement>) => {
    try {
      setIsBadInputNouDateFr(e.target.validity.badInput);

    } catch (e) {
      showBoundary(e);
    }
  }

  // // 納品日（終了）入力処理
  const onChangeNouDateTo = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setRequestGetOrderHistory({
        ...requestGetOrderHistory,
        nou_date_to: e.target.value,
      })
    } catch (e) {
      showBoundary(e);
    }
  }

  // 納品日（終了）フォーカスが離れるときの処理
  const onBlurNouDateTo = (e: FocusEvent<HTMLInputElement>) => {
    try {
      setIsBadInputNouDateTo(e.target.validity.badInput);

    } catch (e) {
      showBoundary(e);
    }
  }

  // // 得意先コンボ変更処理
  const onChangeTokuisaki = (e: ChangeEvent<HTMLSelectElement>) => {
    try {
      setRequestGetOrderHistory({
        ...requestGetOrderHistory,
        tok_cd_from: Number(e.target.value),
        tok_cd_to: Number(e.target.value),
      })
    } catch (e) {
      showBoundary(e);
    }
  };

  // 担当分チェックボックスチェック処理
  const onChangeTantoFlag = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const targetData = e.target.checked
      if (targetData) {
        setRequestGetOrderHistory({
          ...requestGetOrderHistory,
          tan_tok_flg: 1,
        })
      } else {
        setRequestGetOrderHistory({
          ...requestGetOrderHistory,
          tan_tok_flg: 0,
        })
      }
    } catch (e) {
      showBoundary(e);
    }
  }

  return (
    <>
      <Stack m={{ base: 2, md: 4 }}>
        <Heading as="h1" textAlign="left" size="md">
          発注伝票一覧
        </Heading>

        <Box display={{ xl: "flex" }} mt="10px">
          {/* 発注日From */}
          <Flex align="flex-end">
            <Box mr={{ base: "5px", sm: "10px" }}>
              <Text size="xs">発注日</Text>
              <DateInput onChange={onChangeHchuDateFr} onBlur={onBlurHchuDateFr} defaultValue={hachuDateFr} />
            </Box>
            <Text pb={2} pr={{ base: "5px", sm: "10px" }} fontSize={{ base: "lg", sm: "xl" }}>
              ～
            </Text>
            {/* 発注日To */}
            <Box mr="35px">
              <Box>
                <DateInput onChange={onChangeHchuDateTo} onBlur={onBlurHchuDateTo} />
              </Box>
            </Box>
          </Flex>

          {/* 納品日From */}
          <Flex align="flex-end" mt={{ base: "10px", xl: "0px" }}>
            <Box mr={{ base: "5px", sm: "10px" }}>
              <Text size="xs">納品日</Text>
              <DateInput onChange={onChangeNouDateFr} onBlur={onBlurNouDateFr} />
            </Box>
            <Text pb={2} pr={{ base: "5px", sm: "10px" }} fontSize={{ base: "lg", sm: "xl" }}>
              ～
            </Text>

            {/* 納品日To */}
            <Box mr="35px">
              <Box>
                <DateInput onChange={onChangeNouDateTo} onBlur={onBlurNouDateTo} />
              </Box>
            </Box>
          </Flex>

          {/* 検索ボタン ログインデータが一般ユーザの場合表示 */}
          <Flex
            justifyContent={{ base: "flex-end", sm: "normal" }}
            display={login && login.login_data.admin_kbn !== 1 ? "flex" : "none"}
            pb="3px"
            mr="4px"
            mt={{ base: "35px", 'xl': "25px" }}
            ml={{ base: "auto", sm: "300px", xl: "0px" }}
          >
            <PrimaryButton onClick={onClickSearch} bgGradient="linear(blue.400, blue.600)" color="white" width={100} icon={<Icon as={BsSearch} mt="3px" boxSize="14px" />}>
              検索
            </PrimaryButton>

          </Flex>
        </Box>

        <Box
          display={{ lg: "flex" }}
          mt={login && login.login_data.admin_kbn === 1 ? "10px" : ""}>
          <Flex align="flex-end">
            {/* 得意先コンボボックス */}
            {/* ログインデータが管理者の場合表示 */}
            <Box
              display={login && login.login_data.admin_kbn === 1 ? "" : "none"}
              w={{ base: "auto", md: "400px" }}
              maxW="400px"
              minW="200px"
              mr={8}
            >
              <Text size="xs">得意先</Text>
              <Select onChange={onChangeTokuisaki} defaultValue="">
                <option value=""></option>
                {tokuisakiData.map((data) => (
                  <option key={data.tok_cd} value={data.tok_cd}>{data.tok_cd}：{data.tok_mei}</option>
                ))}
              </Select>
            </Box>

            {/* 担当分チェックボックス */}
            {/* ログインデータが管理者の場合表示 */}
            <Stack
              display={login && login.login_data.admin_kbn === 1 ? "" : "none"}
              minW="65px"
              align="center"
              mb="14px"
              mr={{ md: "15px", xl: "240px" }}
            >
              <Text size="xs">担当分</Text>
              <Checkbox
                defaultChecked={login && login.login_data.admin_kbn === 1 ? true : false}
                pt="10px"
                pl="15px"
                onChange={onChangeTantoFlag}
              />
            </Stack>

          </Flex>

          {/* 検索ボタン */}
          {/* ログインデータが管理者の場合表示 */}
          <Box display={login && login.login_data.admin_kbn === 1 ? "" : "none"} textAlign="right" mt="25px" mr="4px">
            <PrimaryButton onClick={onClickSearch} bgGradient="linear(blue.400, blue.600)" color="white" width={100} icon={<Icon as={BsSearch} boxSize="14px" />}>
              検索
            </PrimaryButton>
          </Box>
        </Box>

        <Text mt="10px">発注履歴検索が完了しました。{orderHistory?.hchu_kensu}件該当しました。</Text>

        {/* ページネーション追加時 */}
        {/* <Flex justifyContent="end" mt="10px">

        </Flex> */}

        <TableContainer>
          <Table size="sm">
            <Thead bg="#006DA7">
              <Tr>
                <Th>伝票番号</Th>
                <Th>発注日</Th>
                <Th>納品日</Th>
                <Th>件数</Th>
                <Th>金額</Th>
                <Th>修正 / 削除</Th>
                <Th>ログインID</Th>
                <Th>得意先コード</Th>
                <Th>得意先名</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orderHistory?.hchu_data.map((data, index) => (
                <Tr key={data.den_no} bg={index % 2 === 0 ? "white" : "#D4E5EF"}>
                  <Td textAlign="center">{data.den_no}</Td>
                  <Td textAlign="center">{data.hchu_date}</Td>
                  <Td textAlign="center">{data.nou_date}</Td>
                  <Td textAlign="center"><Link color='blue.500' textDecoration="underline" onClick={() => onClickRefer(data.den_no)}>{data.kensu}件</Link></Td>
                  <Td textAlign="right">{data.kingaku.toLocaleString()}円（税別）</Td>
                  <Td textAlign="center">
                    <SecondaryButton
                      width={80}
                      bgGradient='linear(#6F94CD, #006AB7)'
                      color="white"
                      onClick={() => onClickUpdate(data.den_no)} isDisabled={data.can_be_updated === 0 ? true : false}>
                      修正
                    </SecondaryButton>
                    <SecondaryButton
                      width={80}
                      bgGradient='linear(red.300, red.500)'
                      color="white"
                      onClick={() => onClickDelete(data.den_no)} isDisabled={data.can_be_deleted === 0 ? true : false} margin={2}>
                      削除
                    </SecondaryButton>
                  </Td>
                  <Td textAlign="center">{data.login_id}</Td>
                  <Td textAlign="center">{data.tokcd}</Td>
                  <Td textAlign="left">{data.tokmei}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex justifyContent="end">
          <PageTopScrollButton showButton={showButton} />
        </Flex>
      </Stack>

      <MessageBox
        isOpen={isOpen}
        onClose={onClose}
        onClickConfirmation={() => onClickConfirmationOK()}
        cancelRef={cancelRef}
        messageBoxKbn={messageBoxKbn}
        alertMessage={alertMessage}
      />
    </>
  )
};
import React from 'react'

import { Login } from '../components/pages/Login';
import { Order } from "../components/pages/Order";
import { OrderHistory } from "../components/pages/OrderHistory";
import { OrderDetailHistory } from "../components/pages/OrderDetailHistory";
import { OrderConfirmation } from '../components/pages/OrderConfirmation';
import { StandardItem } from "../components/pages/StandardItem";
import { TeibanFlag } from '../utility/Utility';

// URLと遷移先ページを指定
export const NestRoutes = [
    {
        path: "/",
        chirdren: <Login />,
    },
    {
        path: "/StandardItem",
        chirdren: <StandardItem />,
    },
    {
        path: "/Order",
        chirdren: <Order teibanFlag={TeibanFlag.ShohinIchiran} />,
    },
    {
        path: "/StandardOrder",
        chirdren: <Order teibanFlag={TeibanFlag.Teibanhin} />,
    },
    {
        path: "/OrderHistory",
        chirdren: <OrderHistory />,
    },
    {
        path: "/OrderDetailHistory",
        chirdren: <OrderDetailHistory />,
    },
    {
        path: "/OrderConfirmation",
        chirdren: <OrderConfirmation />,
    },
]